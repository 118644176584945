var sliderMapAgenda = {
    el: null,
    markerList: null,
    _selector: {
        btn_geoloc: "#btn-geoloc",
        slider: "#owl-slider-map-agenda"
    },

    init: function (map) {
        sliderMapAgenda.map._map = map;

        sliderMapAgenda.map.init();
        sliderMapAgenda.slider.init();
    },

    map: {
        _map: null,
        markerMapGeoloc: null,

        init: function () {
            sliderMapAgenda.map.initGeoloc();
            sliderMapAgenda.map.resizeOnOverlayOpen();
        },

        initGeoloc: function () {
            var positionGeoloc = false;

            $(sliderMapAgenda._selector.btn_geoloc).on("click", function (e) {
                e.preventDefault();
                e.stopPropagation();

                var _self = this;

                setTimeout(function () {
                    if (!_self.classList.contains('active')) {
                        console.log('Activation de la geoloc');

                        if (navigator.geolocation) {
                            if (positionGeoloc !== false) {
                                console.log('Geoloc déjà récupéré, affichage');

                                sliderMapAgenda.map.markerMapGeoloc.addTo(sliderMapAgenda.map._map);
                                sliderMapAgenda.map.markerMapGeoloc.setLatLng([positionGeoloc.latitude, positionGeoloc.longitude]);
                                sliderMapAgenda.map._map.panTo([positionGeoloc.latitude, positionGeoloc.longitude]);

                                _self.classList.add('active')
                            } else {
                                console.log('Demande de geoloc ...');
                                navigator.geolocation.getCurrentPosition(function (geoloc) {
                                    positionGeoloc = geoloc.coords;

                                    sliderMapAgenda.map.createMarkerGeoloc();

                                    sliderMapAgenda.map.markerMapGeoloc.addTo(sliderMapAgenda.map._map);
                                    sliderMapAgenda.map.markerMapGeoloc.setLatLng([positionGeoloc.latitude, positionGeoloc.longitude]);
                                    sliderMapAgenda.map._map.panTo([positionGeoloc.latitude, positionGeoloc.longitude]);

                                    _self.classList.add('active');
                                }, function (e) {
                                    console.warn(e);

                                    alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
                                });
                            }
                        } else {
                            alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
                        }
                    } else {
                        console.log('Désactivation de la geoloc');

                        sliderMapAgenda.map._map.removeLayer(sliderMapAgenda.map.markerMapGeoloc);
                        sliderMapAgenda.map.fitBoundsOnMarkerList();

                        _self.classList.remove('active')
                    }
                }, 100);
            });
        },

        createMarkerGeoloc: function () {
            if (!sliderMapAgenda.map.markerMapGeoloc) {
                sliderMapAgenda.map.markerMapGeoloc = th_maps.createMarker(null, th_maps.defaultOptions.center, "marker-locate");
            }
        },

        resizeOnOverlayOpen: function () {
            th_overlay.addCallbackOpen(function (overlay_id) {
                if (overlay_id === "overlay-map-agenda") {
                    var container = $("#" + overlay_id);

                    if (!container.hasClass("parsed")) {
                        sliderMapAgenda.map._map.invalidateSize();

                        sliderMapAgenda.map.fitBoundsOnMarkerList();
                    }
                }
            });
        },

        fitBoundsOnMarkerList: function () {
            if (sliderMapAgenda.markerList) {
                var groupBounds = new L.featureGroup(sliderMapAgenda.markerList);
                sliderMapAgenda.map._map.fitBounds(groupBounds.getBounds());
            }
        }
    },

    slider: {
        _slider: null,

        init: function () {
            sliderMapAgenda.slider.createSlider();
            sliderMapAgenda.slider.reInitOnListingUpdate();
        },

        createSlider: function () {
            var markerlist = [];
            var slider = $(sliderMapAgenda._selector.slider);

            if (slider) {
                $('.item', slider).each(function (k, v) {
                    var card = this;

                    var latlng = {lat: (this.getAttribute('data-lat')), lng: (this.getAttribute('data-lng'))};
                    latlng.lat = latlng.lat.replace(',', '.') * 1;// , incompatible, puis float
                    latlng.lng = latlng.lng.replace(',', '.') * 1;// , incompatible, puis float
                    var marker = th_maps.createMarker(sliderMapAgenda.map._map, latlng, 'marker');

                    var data = {
                        url: $('> a', this).attr('href'),
                        img: {url: $('figure img', this).attr('data-src')},
                        city: $('.location', this).text(),
                        nom: $('h2', this).text(),
                        more: $('.link', this).text()
                    };

                    if (document.body.clientWidth >= 600) {
                        th_maps.createInfoWindow('<a target="_blank" href="' + data.url + '" id="map-inte-container">' +
                            '<div class="map-inte-content">' +
                            '<div class="map-inte-header">' +
                            '<figure class="fit-cover"><img src="' + data.img.url + '" width="260" height="160" alt="" class="fit-cover"' +
                            ' /></figure>' +
                            '</div>' +
                            '<div class="map-inte-content-text">' +
                            '<div class="wrapper-rate"><span class="location">' + data.city + '</span></div><h3>' + data.nom + '</h3>' +
                            '<span class="link">' + data.more + '</span>' +
                            '</div></div></a>', marker, 260);
                    } else {
                        marker.addEventListener('click', function () {
                            slider.trigger('to.owl.carousel', k);
                        });
                    }

                    $('*', card).on('focus', function () {
                        marker.setIcon(th_maps.markersIcons['marker-hover']);
                    }).on('blur', function () {
                        marker.setIcon(th_maps.markersIcons['marker']);
                    });

                    $(card).on('mouseenter focus', function () {
                        marker.setIcon(th_maps.markersIcons['marker-hover']);
                    }).on('mouseleave', function () {
                        marker.setIcon(th_maps.markersIcons['marker']);
                    });

                    markerlist.push(marker);
                });
            }

            sliderMapAgenda.markerList = markerlist;
        },

        reInitOnListingUpdate: function () {
            listingSit.callbacks.afterListingUpdate = function (data) {
                // Reinit du slider
                $(sliderMapAgenda._selector.slider).html($(sliderMapAgenda._selector.slider, data).html());

                if ($('.item', sliderMapAgenda._selector.slider).length) {
                    var lazyLoadInstance = new LazyLoad({
                        elements_selector: ".lazy"
                    });
                    $(sliderMapAgenda._selector.slider).trigger("destroy.owl.carousel");
                    $(sliderMapAgenda._selector.slider).on('initialized.owl.carousel', function () {
                        // Do something
                        thAnimParse($(sliderMapAgenda._selector.slider));
                    });
                    $(sliderMapAgenda._selector.slider).owlCarousel({
                        loop: false,
                        margin: 20,
                        dots: true,
                        nav: true,
                        items: 4,
                        autoWidth: true,
                        navText: ["<button tabindex='0'><span class='icon-ico-arrow-left' aria-hidden='true'></span><span class='sr-only'>Voir la précédente slide</span></button>", "<button tabindex='0'><span" +
                        " class='icon-ico-arrow-right'" +
                        " aria-hidden='true'></span><span class='sr-only'>Voir la slide suivante</span></button>"],
                    });
                }

                // TODO : Remove les markers et re-init le slider
                $.each(sliderMapAgenda.markerList, function () {
                    sliderMapAgenda.map._map.removeLayer(this);
                });

                sliderMapAgenda.slider.createSlider();
            };
        }
    }
};