if($('.navscroll').length > 0){
    $('.navscroll li:not(.landing-scroll) a, .navscroll .item:not(.landing-scroll) a').click(function(e){
        // smooth scroll to anchor + offset
        e.preventDefault();
        var dest = $(this).attr('href');
        var finalPos = $(dest).offset().top;
        $('html,body').animate({scrollTop:finalPos - 100},500);

        // window.scroll({
        //     top: finalPos-80,
        //     left: 0,
        //     behavior: 'smooth'
        // });
    });
}

$('[data-anchor]').each(function(){
    $(this).on('click',function(e){
        e.preventDefault();
        var anchor = $(this).attr('data-anchor');
        if(anchor == 'scroll')
        {
            $('html, body').animate({scrollTop:($('html,body').scrollTop()+760)},'slow');
        }else{
            $('html, body').animate({scrollTop:$($(this).attr('href')).offset().top},'slow');
        }
        return false;
    });
});