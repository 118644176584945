var modal = document.getElementById('overlay-phototheque');
var btn = document.getElementsByClassName("btn-modal");
var span = document.getElementsByClassName("close")[0];

$('.btn-modal').click(function(e){
	e.preventDefault();
	e.stopPropagation();
	$('.modal-body','#overlay-phototheque').html(
		($($(this).data('modal')).html())
			.replace('src=','oldsrc=')
			.replace('data-src=','src=')
			.replace('type="radio" id=','type="radio" oldid=')
			.replace('data-inputid=','id=')
			.replace('data-inputid=','id=')
			.replace('data-inputid=','id=')
			.replace('data-inputid=','id=')
	);
	$('input','#overlay-phototheque').focusin(function () {
		$(this).parents('.form-field:first').addClass('field-is-focus');
	});

	$('input','#overlay-phototheque').focusout(function () {
		if ($(this).val().length < 1) {
			$(this).parent().removeClass('field-is-focus');
		}
	});


	$mediaForm = $('form','#overlay-phototheque');
	if($mediaForm.length > 0){
		$mediaForm.on('submit',function(e){
			e.preventDefault();
			$.post(
				$(this).attr('action'),
				$(this).serialize(),
				function(response){
					if(response){
						if(response.success){
							$mediaForm.replaceWith('<a class="btn" target="_blank" href="'+response.url+'"><span>Télécharger la photo</span></a>');
						}else{
							alert('Identifiant ou mot de passe incorrect');
						}
					}
				},
				'json'
			);
		});
	}

});

$('.close').click(function(){
	$('.modal-content').removeClass("modal-show");
	setTimeout(function(){ 
		$('#contactModal').removeClass('show');
	}, 300);
});

$(window).click(function(event) {
	if (event.target == modal) {
		$('.modal-content').removeClass("modal-show");
		setTimeout(function(){ 
			$('#contactModal').removeClass('show');
		}, 300);   
	}
});