var thuriaSearch = {

    scriptLoaded: false,

    client: null,
    index: null,
    indexLecture: null,
    indexPro: null,
    basic_filters: '',

    input: null,
    searchContainer: null,
    searchScrollContainer: null,
    searchContainerLecture: null,
    searchContainerPro: null,
    scrollers: {},

    lastSearch: 0,

    lastText: null,
    lastResultNbPage: 0,
    lastHitsCountPerIndex: {},
    lastPage: 0,
    lastPostHits: null,
    lastPostHitGenerated: 0,
    intervalGenerateHit: null,
    intervalGeneratePostHit: null,

    lockNextPage: false,
    noresultPhrase: null,

    timeoutInputLimit:null,

    init: function(id,key,indexApidae,indexLecture,Indexpro,input,searchContainer,searchScrollContainer, searchContainerLecture, searchContainerPro){

        if(typeof algoliasearch == 'undefined'){
            setTimeout(function(){
                thuriaSearch.init(id,key,indexApidae,indexLecture,Indexpro,input,searchContainer,searchScrollContainer, searchContainerLecture, searchContainerPro);
            },500);
            return false;
        }

        thuriaSearch.basic_filters = "taxonomies.language:" + $('html').data('fulllang') + ' AND has_ended:false';
        thuriaSearch.client = algoliasearch(id, key);
        thuriaSearch.index = indexApidae;
        thuriaSearch.indexLecture = indexLecture;
        thuriaSearch.indexPro = Indexpro;
        thuriaSearch.input = input;
        thuriaSearch.searchContainer = searchContainer;
        thuriaSearch.searchScrollContainer = searchScrollContainer;
        thuriaSearch.searchContainerLecture = searchContainerLecture;
        thuriaSearch.searchContainerPro = searchContainerPro;

        if(typeof thuriaSearch.searchContainer != "undefined") {

            thuriaSearch.noresultPhrase = thuriaSearch.searchContainer.getAttribute('data-noresult');
            thuriaSearch.initInputEvents();
            thuriaSearch.initScrollEvents();
            thuriaSearch.initCarousels();

            console.log(thuriaSearch);
        }

        return true;
    },

    initInputEvents: function(){
        thuriaSearch.input.addEventListener('keydown',function(){
            setTimeout(function(){
                thuriaSearch.search(thuriaSearch.input.value);
                thuriaSearch.hasResults();
            },25);
        });
    },

    initScrollEvents: function(){
        thuriaSearch.searchScrollContainer.addEventListener('touchstart',function() {
            if(thuriaSearch.input.id === document.activeElement.id){
                document.getElementById('search-fake-btn').focus();
            }
        });
        thuriaSearch.searchScrollContainer.addEventListener('scroll',function(){

            if(thuriaSearch.searchScrollContainer.scrollTop > 0){
                if((thuriaSearch.searchScrollContainer.scrollHeight-thuriaSearch.searchScrollContainer.scrollTop-thuriaSearch.searchScrollContainer.getBoundingClientRect().height) < 1024){
                    thuriaSearch.nextPage('index');
                }
            }
        });
    },

    /***
     *
     * @param query
     * @param checkLastResult
     * @param indexs : précise le tableau d'indexs à parcourir
     * @returns {Array}
     */
    generateQueries: function(query, checkLastResult, indexs){

        var finalQueries = [];

        $.each(indexs,function(k,indexName){

            // pages et autres
            var isOk = false;
            if (checkLastResult) {
                if (thuriaSearch.lastHitsCountPerIndex[indexName] > 0) {
                    isOk = true;
                }
            }
            if (!checkLastResult || isOk) {
                var q = $.extend(false, query, {indexName: indexName});
                finalQueries.push(q);
            }

        });

        return finalQueries;
    },

    search: function(text){
        thuriaSearch.lastText = text;
        thuriaSearch.lastSearch++;
        var queryId = thuriaSearch.lastSearch;

        if(thuriaSearch.lastText.length > 2) {

            // un peu de lecture : page + magazine
            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: 0, hitsPerPage: 12, filters: thuriaSearch.basic_filters}
            },false, thuriaSearch.indexLecture);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    thuriaSearch.handleSearchResultCarousel(thuriaSearch.searchContainerLecture, results, queryId);
                }
            });

            // pour les pro : posts
            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: 0, hitsPerPage: 12, filters: thuriaSearch.basic_filters}
            },false, thuriaSearch.indexPro);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    thuriaSearch.handleSearchResultCarousel(thuriaSearch.searchContainerPro, results, queryId);
                }
            });

            // Apidae
            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: 0, hitsPerPage: 12, filters: thuriaSearch.basic_filters}
            }, false , thuriaSearch.index);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    thuriaSearch.handleSearchResult(results, queryId, false);
                }
            });

        } else {
            thuriaSearch.handleSearchResult({hits:[]},queryId);
            thuriaSearch.handleSearchResultCarousel(thuriaSearch.searchContainerLecture, {hits:[]}, queryId);
            thuriaSearch.handleSearchResultCarousel(thuriaSearch.searchContainerPro, {hits:[]}, queryId);
        }

    },

    nextPageCarousel: function(index){

        var indexes = thuriaSearch[index];
        var num_page = 0;
        var carousel_id = '';

        switch (index) {
            case 'indexLecture':
                carousel_id = 'owl-search-lecture';
                num_page = thuriaSearch.scrollers[carousel_id].page;
                break;
            case 'indexPro':
                carousel_id = 'owl-search-pro';
                num_page = thuriaSearch.scrollers[carousel_id].page;
                break;
        }

        if(num_page < thuriaSearch.scrollers[carousel_id].nbpages) {
            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {
                    page: num_page,
                    hitsPerPage: 12,
                    filters: thuriaSearch.basic_filters
                }
            }, true, indexes);


            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    var loading = false;

                    if (results[0].page < (results[0].nbPages - 1)) {
                        thuriaSearch.scrollers[carousel_id].page = thuriaSearch.scrollers[carousel_id].page + 1;
                    }

                    if((num_page + 1) == thuriaSearch.scrollers[carousel_id].nbpages) {
                        // indique que c'est la dernière page a demander pour cette query
                        loading = true;
                    }

                    thuriaSearch.handleResponseCarousel(results, carousel_id, loading);
                }
            });


        }

    },

    nextPage: function(index){

        if(thuriaSearch.lockNextPage == false && thuriaSearch.lastResultNbPage > 1 && thuriaSearch.lastResultNbPage > thuriaSearch.lastPage) {
            thuriaSearch.lockNextPage = true;

            var indexes = thuriaSearch[index];
            var num_page = thuriaSearch.lastPage;


            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {
                    page: num_page + 1,
                    hitsPerPage: 12,
                    filters: thuriaSearch.basic_filters
                }
            }, true, indexes);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {

                    var results = result.results;

                    var lastPage = null;
                    var lastNbPage = 0;

                    $.each(results, function (k, r) {
                        if (r.nbPages > lastNbPage) {
                            lastNbPage = r.nbPages;
                            lastPage = r.page;
                            thuriaSearch.lastHitsCountPerIndex[r.index] = r.hits.length;
                        }
                    });

                    thuriaSearch.lastPage = lastPage;
                    thuriaSearch.lastResultNbPage = lastNbPage;
                    thuriaSearch.handleResponse(results, thuriaSearch.searchContainer);

                }
            });
        }

    },

    handleSearchResult: function(results,queryId){
        if(thuriaSearch.lastSearch == queryId){

            thuriaSearch.searchContainer.innerHTML = '';
            thuriaSearch.searchContainer.scrollTop = 0;
            thuriaSearch.lockNextPage = false;

            var $containernbres =  $(thuriaSearch.searchContainer).prev();
            $containernbres = $('.nb',$containernbres);

            var lastPage = null;
            var lastNbPage = 0;
            var nb_total_res = 0;

            $.each(results,function(k,r){
                if(r.nbPages > lastNbPage){
                    lastNbPage = r.nbPages;
                    lastPage = r.page;
                }

                if(typeof r.hits != 'undefined') {
                    thuriaSearch.lastHitsCountPerIndex[r.index] = r.hits.length;
                    nb_total_res = nb_total_res + r.nbHits;
                } else {
                    thuriaSearch.lastHitsCountPerIndex[r.index] = 0;
                }
            });

            thuriaSearch.lastPage = lastPage;
            thuriaSearch.lastResultNbPage = lastNbPage;

            if(lastNbPage > 0){
                $containernbres.text(nb_total_res);
                thuriaSearch.handleResponse(results,thuriaSearch.searchContainer);
                if($(thuriaSearch.searchContainer.parentElement).hasClass('noresult')) {
                    $(thuriaSearch.searchContainer.parentElement).removeClass('noresult');
                }
            } else {
                $containernbres.text(0);
                thuriaSearch.searchContainer.innerHTML = '<p>'+thuriaSearch.noresultPhrase+'</p>';
                $(thuriaSearch.searchContainer.parentElement).addClass('noresult');
            }
        }
    },

    handleSearchResultCarousel: function(container, results,queryId){

        var carousel_id = container.getAttribute('id');

        if(thuriaSearch.lastSearch == queryId){

            thuriaSearch.emptyCarousel(carousel_id);

            if(results.length > 0){

                var nb_results = 0;
                var nb_total_res = 0;
                var nb_max_page = 0;

                var $containernbres =  $(container).prev();
                $containernbres = $('.nb',$containernbres);

                $.each(results,function(k,result){

                    if(typeof result.hits != 'undefined') {
                        thuriaSearch.lastHitsCountPerIndex[result.index] = result.hits.length;
                        nb_total_res = nb_total_res + result.nbHits;
                        if(result.nbPages > nb_max_page) {
                            nb_max_page = result.nbPages;
                        }
                    } else {
                        thuriaSearch.lastHitsCountPerIndex[result.index] = 0;
                    }

                    $.each(result.hits,function(k2,hit){
                        nb_results ++;
                    });
                });

                if(nb_results > 0) {
                    thuriaSearch.scrollers[carousel_id].page  = thuriaSearch.scrollers[carousel_id].page + 1;
                    thuriaSearch.scrollers[carousel_id].nbpages = nb_max_page;
                    $containernbres.text(nb_total_res);
                    thuriaSearch.handleResponseCarousel(results,carousel_id);
                } else {
                    $containernbres.text(0);
                    thuriaSearch.emptyCarousel(carousel_id);
                }

            } else {
                thuriaSearch.emptyCarousel(carousel_id);
            }

        }
    },

    handleResponse: function(results, container, wrapperclass){

        var html = '';

        $.each(results,function(k,result){
            $.each(result.hits,function(k2,hit){
                if(wrapperclass) {
                    html += '<div class="'+wrapperclass+'">'+hit.cards_html+'</div>';
                } else {
                    html += hit.cards_html;
                }
            });
        });

        container.innerHTML += html;
        init_ajaxmail_nonpartenaire();

        setTimeout(function(){
            thuriaSearch.lockNextPage = false;
        },100);
    },

    handleResponseCarousel: function(results, carousel_id, loading){

        $.each(results,function(k,result){
            $.each(result.hits,function(index,hit){

                var card = '<div class="item">'+hit.cards_html+'</div>';

                if(!thuriaSearch.scrollers[carousel_id].carousel && index == 0) {

                    // Au premier élément qu'on ajoute dans le carousel il faut également l'initalisé

                    $carousel = $('#'+carousel_id);
                    $carousel.append($(card));
                    thuriaSearch.scrollers[carousel_id].carousel = $('#'+carousel_id).owlCarousel(thuriaSearch.getOptionsCarousel($carousel));

                } else {
                    thuriaSearch.scrollers[carousel_id].carousel.trigger('add.owl.carousel', [$(card)]);
                    thuriaSearch.scrollers[carousel_id].carousel.trigger('refresh.owl.carousel');
                }
            });
        });

        if(typeof loading == "undefined") {
            loading = false;
        }

        thuriaSearch.scrollers[carousel_id].loading = loading;


        if($('#' + carousel_id).parent().hasClass('noresult')) {
            $('#' + carousel_id).parent().removeClass('noresult');
        }

        setTimeout(function(){
            thuriaSearch.lockNextPage = false;
        },100);
    },

    emptyCarousel:function (carousel_id) {

        thuriaSearch.scrollers[carousel_id].page = 0;
        if(thuriaSearch.scrollers[carousel_id].carousel != false) {
            thuriaSearch.scrollers[carousel_id].carousel.trigger('destroy.owl.carousel');
            thuriaSearch.scrollers[carousel_id].carousel = false;
            thuriaSearch.scrollers[carousel_id].loading = false;
            thuriaSearch.scrollers[carousel_id].page = 0;
            $('#'+carousel_id).html('');
        }

        $('#'+carousel_id).parent().addClass('noresult');
    },

    initCarousels: function () {

        $('.owl-algolia').each(function () {
            var _self = $(this);
            var _id = $(this).attr('id');

            _self.parent().addClass('noresult');

            thuriaSearch.scrollers[_id] = {};
            thuriaSearch.scrollers[_id].elem = _self;
            thuriaSearch.scrollers[_id].carousel = false;
            thuriaSearch.scrollers[_id].loading = false;
            thuriaSearch.scrollers[_id].page = 0;
        });
    },

    getOptionsCarousel: function($elem) {

        var options = {
            loop: false,
            margin: 10,
            dots: true,
            nav: true,
            items: 3,
            autoWidth: true,
            navText: ["<span class='icon-ico-arrow-left'></span>", "<span class='icon-ico-arrow-right'></span>"],
            onTranslated: algoliaTranslateCarousel,
            onDragged: algoliaTranslateCarousel,
        };

        var data = $elem.data();

        $.each(data, function (key, data) {

            if (key.match(/(items|loop|margin|center|autoWidth|autoheight|nav|dots|autoplay|autoplayspeed)/gi)) {
                options[key] = data;
            }

        });

        return options;
    },

    hasResults :function() {

        setTimeout(function(){
            if($('#fast-search-container-cms').hasClass('noresult') && $('#fast-search-container').hasClass('noresult') && $('#fast-search-container-pages-pro').hasClass('noresult')){
                $('.wrapper-search-algolia').addClass('no-results');
            }else{
                $('.wrapper-search-algolia').removeClass('no-results');
            }
        },500);

    }

};

function algoliaTranslateCarousel(event) {
    var element = event.target;
    var carousel_id = element.getAttribute('id');

    switch (carousel_id) {
        case 'owl-search-lecture':
            var index = 'indexLecture';
            break;
        case 'owl-search-pro':
            var index = 'indexPro';
            break;
    }

    var nb_items = event.page.count;
    var current_item = event.page.index;

    if((nb_items - current_item) < 5 && thuriaSearch.scrollers[carousel_id].loading == false) {
        console.log('next page');
        thuriaSearch.nextPageCarousel(index);
    }
}

