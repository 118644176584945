// $('[data-frmval]').each(function () {
//     this.setAttribute('placeholder', this.getAttribute('data-frmval'));
//     if (this.value === this.getAttribute('data-frmval')) {
//         this.value = '';
//     }
// });


var currentLangFormidable = 'fr';
var buttonUploadText = {
    'fr': "+"
}

$('.frm_single_upload input[type="file"], .frm_multi_upload input[type="file"]').addClass('jfilestyle').attr('data-buttonText', buttonUploadText[currentLangFormidable]);
$('.frm_single_upload input[type="file"], .frm_multi_upload input[type="file"]').parents('.form-field').addClass('input-type-file');


$('.frm_radio > label').append('<span></span>');

$('.frm_checkbox > label').append('<span></span>');


$('.frm_single_upload input[type="file"], .frm_multi_upload input[type="file"]').on('click', function () {

    if ($(this).parents('.jfilestyle').first('input div').value != '') {
        $(this).parents('.form-field:first').addClass('field-is-focus');
    } else {
        $(this).parents('.form-field:first').removeClass('field-is-focus');
    }

});


$('.contact .bloc-form input, .wrapper-bloc-form .bloc-form input').focusin(function () {
    $(this).parents('.form-field:first').addClass('field-is-focus');
});

$('select, .selectric-input', '.contact .bloc-form', '.wrapper-bloc-form .bloc-form').each(function () {
    if (this.value != '') {
        $(this).parents('.form-field:first').addClass('field-is-focus');
    }
    $(this).on('change', function () {
        $(this).parents('.form-field:first').addClass('field-is-focus');
    });
});

$('.bloc-form select').each(function () {
    if ($(this).val() != "") {
        $(this).parents('.form-field:first').addClass("field-is-focus");
    } else {
        $(this).parents('.form-field:first').removeClass("field-is-focus");
    }
});

$(".bloc-form select").focusout(function () {
    if ($(this).val() != "") {
        $(this).parents('.form-field:first').addClass("field-is-focus");
    } else {
        $(this).parents('.form-field:first').removeClass("field-is-focus");
    }
});


$(".bloc-form select").focus(function () {
    $(this).parents('.form-field:first').addClass("field-is-focus");
});


$('.contact .bloc-form input, .wrapper-bloc-form .bloc-form input').focusout(function () {
    if ($(this).val().length < 1) {
        $(this).parent().removeClass('field-is-focus');
    }
});

$('.contact .bloc-form input, .wrapper-bloc-form .bloc-form input').each(function () {
    if (this.value.length > 0 && this.value.length >= 1) {
        $(this).parent().addClass('field-is-focus');
    }
});


var $scrollToEl = $('[data-scrolltothis="true"]');
if ($scrollToEl.length > 0) {
    var offsetScrollTo = $scrollToEl.offset();
    $(document).scrollTop(offsetScrollTo.top - 80);
}


// Gestion des états label dans le form de la médiathèque
$('.haute-def form input').focusin(function () {
    $(this).parents('.form-field:first').addClass('field-is-focus');
});


$('.haute-def input').focusout(function () {

    if ($(this).val().length < 1) {
        $(this).parent().removeClass('field-is-focus');
    }
});

$('.haute-def input').each(function () {
    if (this.value.length > 0 && this.value.length >= 1) {
        $(this).parent().addClass('field-is-focus');
    }
});


$('.bloc-form textarea').focusin(function () {
    $(this).parents('.form-field:first').addClass('field-is-focus');
});


$('.bloc-form textarea').focusout(function () {

    if ($(this).val().length < 1) {
        $(this).parent().removeClass('field-is-focus');
    }
});


$('.pays_field select').customA11ySelect();

// Ce champ est initialisé avec la librairie JS "Chosen". Mais que je sois pendu par les pieds si on trouve ou ça se fait ! Donc en urgence et en attendant, on fait comme ça...
var fieldPaysWrapper = $('.pays_field').closest(".frm_form_field");
setTimeout(function(){
    $('.chosen-container',fieldPaysWrapper).remove();
},200);


/*
* Le script suivant est utilisé dans le formulaire newsletter dans le footer, afin de forcer le input field à sortir de son container qui est un élément label.
* L'objectif c'est d'avoir quelque chose de plus conforme à l'RGAA
 */


var checkboxContainer = $(".frm_form_field .frm_checkbox");
var checkboxInput = $('.frm_form_field .frm_checkbox input[type=checkbox]');


checkboxContainer.each(function () {

    var checkboxInput = $(this).find('input[type=checkbox]');
    $(this).prepend(checkboxInput);
});

// On ajoute également un span après l'input field pour pouvoir modifier l'aspect du checkbox comme dans la maquette

checkboxInput.after("<span class='personalized-input'></span>");

var radioContainer = $('.frm_form_field .frm_radio');
var radioInput = $('.frm_form_field .frm_radio input[type=radio]');

radioContainer.each(function () {

    var radioInput = $(this).find('input[type=radio]');
    $(this).prepend(radioInput);
});

radioInput.after("<span class='personalized-input'></span>");

/*
* Pour tous les champs checkbox on ajoute un attribut aria-checked pour les lecteurs d'écran afin de savoir quel est l'état du checkbox cliqué
 */


var allCheckboxes = $('input[type=checkbox]');

allCheckboxes.attr('aria-checked');

allCheckboxes.click(function () {

    if ($(this).attr('aria-checked') === 'true') {
        $(this).attr('aria-checked', false);
    } else {
        $(this).attr('aria-checked', true);
    }
});


/*
* On ajoute l'attribut aria-checked égale à "true" sur le input radio sélectionné
 */

$(document).ready(function () {
    $("input[type=radio]").on('click focus', function () {

        $('input[type=radio]').each(function () {

            $(this).attr("aria-checked", "false");
        });

        $(this).attr("aria-checked", "true");

    });
});


// RGAA : Suppression du fieldset
if ($('.newsletter fieldset').length > 0) {

    $('.newsletter fieldset').each(function () {
        $(this).replaceWith($('<div class="fieldset">' + this.innerHTML + '</div>'));
    });
}


// Gestion du focus dans la Newsletter
$('.newsletter input[type="email"]').on('focus', function () {
    $(this).parents('.form-field:first').addClass('has-content');
});

$('.newsletter input[type="email"]').on('focusout', function () {
    if ($(this).val() === "") {
        $(this).parent().removeClass('has-content');
    }
});


$('.pays_field .custom-a11yselect-btn').on('click focus', function () {
    $('.pays_field label').addClass('moved');
});

$('.pays_field ul li').first().find('button').on('click focus', function () {
    $('.pays_field label').removeClass('moved');
});
