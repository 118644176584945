$('.zoombox').zoombox();


th_overlay.init();
th_expandModified.parse();
th_video.init();

// Lancement du script de ObjectFit
objectFitImages('.fit-cover img');


$('.pulse').on("click", function () {
    $('.img-map').toggleClass('panel-display');
});

var Ww = $(window).width();

if (isTouchDevice() && Ww <= 1280) {
    $('.lang > .sub-menu').addClass('sub-lang-mobile');
    $('#lang-mobile').addClass('is-display');
    $('#lang-mobile-menu').addClass('is-display');
}
;


$('.retour .close').click(function (e) {
    e.preventDefault();
    $('.retour').addClass('hide');
});

var stickyItemPosition = 0;
var $stickyItem = $('.page-detail .aside-sticky');
if ($stickyItem.length > 0) {
    moveTheStickyContainerAside();
    $(window).on('resize', moveTheStickyContainerAside);
    $(window).on('orientationchange', moveTheStickyContainerAside);
}

function moveTheStickyContainerAside() {
    if (document.body.clientWidth < 980 && stickyItemPosition == 0) {
        $('#append-aside-here').append($stickyItem);
        stickyItemPosition = 1;
    }
    if (document.body.clientWidth > 980 && stickyItemPosition == 1) {
        $('.page-detail > .content-wrapper').append($stickyItem);
        stickyItemPosition = 0;
    }
}


$('.section-wrapper-facette,.wrapper-progressbar').addClass('fade-display');


var countTelNumber = 1;

function clickTelHandleFirst(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).off('click', clickTelHandleFirst);
    $(this).text($(this).data('num'));
    $(this).attr('href', $(this).data('href'));
}

$('.menu-footer a[href^="tel:"].btn').each(function () {
    $(this).attr('id', 'tel-num-' + countTelNumber);
    $(this).on('click', clickTelHandleFirst);
    $(this).attr('data-num', $(this).text());
    $(this).attr('data-href', $(this).attr('href'));
    $(this).attr('href', '#tel-num-' + countTelNumber);
    $(this).text($(this).data('text'));
    countTelNumber++;
});


function RemoveAccents(strAccents) {
    var strAccents = strAccents.split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents = ' ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "_AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
}

$('a[href^="http://www.clermont-fd.com"],a[href^="https://www.clermont-fd.com"]').each(function () {
    var label = $(this).text();
    if (!label || $('.btn', this).text()) {
        label = $('.btn', this).text();
    }
    label = RemoveAccents(label);
    label = label.toUpperCase();
    $(this).attr('data-gacat', 'clic-liens-sortants');
    $(this).attr('data-gaaction', 'CF');
    $(this).attr('data-galabel', 'clic-LS-CF-' + label);
});


$('a[href^="http://www.clermont-fd.com"],a[href^="https://www.clermont-fd.com"]').on('click', function () {

    var category = $(this).attr('data-gacat');
    var action = $(this).attr('data-gaaction');
    var label = $(this).attr('data-galabel');

    if (!action || action == '') {
        action = 'click';
    }

    var data = {
        'event_category': category,
        'event_label': label
    };
    console.log('GA Event ga', data);
    ga('send', 'event', category, action, label);
});


var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy"
});


$('.input-number-increment').click(function () {
    var $input = $(this).parents('.input-number-group').find('.input-number');
    var val = parseInt($input.val(), 10);
    $input.val(val + 1);
});

$('.input-number-decrement').click(function () {
    var $input = $(this).parents('.input-number-group').find('.input-number');
    var val = parseInt($input.val(), 10);
    $input.val(val - 1);
})


// On initialize la librairie customA11 pour créer des champs select accessibles

$(function () {
    $('.select-personalized').customA11ySelect();
});


// Gestion de la mise en pause et démarrage des vidéos de couverture dans la page d'accueil et des pages édito.
// On change également l'icone play / pause selon le click de l'utilisateur

var videoAccueil = $('.video-couverture');

var btnVideo = $('.video-play');

var btnVideoIcon = $('.video-play span');

btnVideo.on('click', function () {
    btnVideoIcon.attr('class', 'icon-controller-paus');
    if (videoAccueil[0].paused) {
        videoAccueil[0].play();
        btnVideoIcon.attr('class', 'icon-ico-pause');
        btnVideo.attr('aria-label', 'Mettre en pause la vidéo');

    } else {
        videoAccueil[0].pause();
        btnVideoIcon.attr('class', 'icon-ico-lecteur');
        btnVideo.attr('aria-label', 'Démarrer la vidéo');
    }

});


// Si les crédits d'images sont contenus dans un lien, on enlève l'attribut tabindex (on ne veut pas tabuler dedans dans ce cas)
$('a .credits').removeAttr('tabindex');


// Au click sur le bouton "pulse" dans le footer, on change l'attribut aria-expended pour signaler au lecteur d'écran qu'on a ouvert ou pas la modale
var btnPlus = $('.pulse');
var btnExpanded = $('.btn-expand');

$('.pulse, .btn-expand').on("click", function () {
    // On change la valeur de l'attribut aria-expanded à true ou false selon si on vient d'ouvrir ou fermer la modale
    if ($(this).attr('aria-expanded') == 'true') {
        $(this).attr('aria-expanded', 'false');
    } else {
        $(this).attr('aria-expanded', 'true');
    }
});


// Ce script à été ajouté parce qu'on a besoin d'avoir sur tous les liens avec un target=blank un texte informant l'utilisateur que le lien sera ouvert dans une nouvelle fenêtre

var targetTranslation = $('#target-translation');
var targetTranslationContent = targetTranslation.text()

$('a[target="_blank"]').each(function () {
    $(this).attr("title", function () {
        if ($(this).attr("title")) {
            return $(this).attr("title") + " (" + targetTranslationContent + ")";
        } else {
            return $(this).text() + " (" + targetTranslationContent + ")";
        }

    });
});
targetTranslation.remove();


// Init sur les overlays de la librairie th_rgaa_focus pour faire un trap focus dans l'overlay
th_overlay.addCallbackOpen(function (callback) {
    var el = $("#" + callback);


    var firstEl = $(".close-overlay", el);
    var lastEl = $('.reset', el);

    firstEl.focus();

    setTimeout(function () {
        th_rgaa_focus.init(el);
    }, 500);

});


// On ajoute la possibilité d'utiliser le autocomplete sur le champ email dans le footer
$('input[type=email]').attr('autocomplete', 'email');

// Dans le modèle de page Jeux Olympiques 2024 au click sur un anchor link dans le menu de navigation INTERNE, le focus doit se déplacer vers la zone avec l'id séléctionné et cibler le premier lien

$('.nav-interne .slick-navscroll .item a').click(function () {
    var anchorId = $(this).attr('href');
    $(anchorId).focus();
});


// Social Share
$('.social-share .toggle').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('open');

    if (e.target.tagName != 'A') {
        if ($(this).hasClass('open')) {
            $(this).attr('aria-expanded', 'true');
        } else {
            $(this).attr('aria-expanded', 'false');
        }
    }

});


//Newsletter

if ($(".newsletter").length > 0) {
    if ($(".newsletter input[type=email]").val().length > 0) {
        $(".newsletter .frm_form_field").addClass('has-content');
    } else {
        $(".newsletter .frm_form_field").removeClass('has-content');
    }
}

var radioDate = $('.group-date input[type=radio]');

radioDate.on('click', function () {
    $(this).attr('checked');

    if ($(this).attr('checked')) {
        $(this).attr('checked', 'false');
    }
});


// Ce script gère le fonctionnement du menu caché "Skip links" pour l'accessibilité.
// Si l'utilisateur clique sur le deuxième lien "Aller au contenu" on va défiler la page vers le premier div après le header et mettre le focus sur le premier élément focusable

$('#go-to-content').on('click', function () {

    $([document.documentElement, document.body]).animate({
        scrollTop: $('#content header + div, #content > section, section, #content > article').offset().top - 80
    }, 1000);

    $('#content header + div, #content > section, #content > article').find($("a[href], button:not(.btn-expand), select:not(.select-personalized), .custom-a11yselect-container button,[tabindex]:not(span)")).first().focus();

});

// Lorsqu'on est dans la page du formulaire, au click sur le bouton "Aller vers le contenu" on déscend vers un input spécifique. Si on laisse le comportement par défault le focus va être mis sur
// le premier input trouvé qui peut être caché

$('.page-template-page-formulaire #go-to-content').on('click', function () {
    $('#content .frm_top_container .frm_radio input').focus();
});


$('#menu-opener').on('click', function (e) {
    e.preventDefault();
    $('#menu label').focus();
});


// Gestion de la classe "active" et du status de l'aria-expanded dans les boutons dans le menu fullscreen
var menuLabels = $('#header-v2 .left-sidebar .menus-left li');
menuLabels.on('click', function () {
    menuLabels.each(function () {
        $(this).removeClass('active');
        $(this).attr('aria-selected', 'false');
        $(this).find('button').attr('aria-expanded', 'false');
    });
    $(this).addClass('active');
    $(this).attr('aria-selected', 'true');
    $(this).find('button').attr('aria-expanded', 'true');
});


var btnOuvertureMenu = $('#menu .button-menu');

var header = $("#header-v2");

var inputGestionMenu = $('#menu-toggle');

$(document).ready(function () {
    header.find('#nav-v2').addClass('add-animation');
    inputGestionMenu.prop('checked', false);
});

btnOuvertureMenu.on('keydown', function (e) {
    var code = e.keyCode || e.which;
    if (code == 13 || code == 32) {
        header.addClass('menu-open');
    }
});
