// Changement de la gestion de l'animation dans le bloc top 10:
// En effet dans le modèle de page top-10 on peut avoir plusieurs blocs à animer ce qui cause des soucis de throttle dans la librairie Waypoints
// L'utilisation de l'intersection observer permet donc de toujours avoir l'animation et que la performance soit meilleure

if (typeof isTouchDevice == 'undefined') {
    function isTouchDevice() {
        return 'ontouchstart' in document.documentElement;
    }
}


if (!isTouchDevice()) {
    $('[data-anim]').each(function () {
        $(this).attr('data-anim-hide', true);
    });

    var observerOptions = {
        rootMargin: '0px',
        threshold: 0.5
    }

    var observer = new IntersectionObserver(observerCallback, observerOptions);

    function observerCallback(entries, observer) {
        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting) {
                entries[i].target.setAttribute("data-anim-hide", false)
            }
        }
    }

    var target = '[data-anim]';
    document.querySelectorAll(target).forEach((i) => {
        if (i) {
            observer.observe(i);
        }
    });
}

