if ($('.page-landing .nav-interne').length > 0) {

    var elementSticky = $('.nav-interne');
    var pos = elementSticky.offset();

    document.onscroll = function () {
        if (pos.top <= $(window).scrollTop()) {
            $('body').addClass('nav-sticky');
        } else {
            $('body').removeClass('nav-sticky');
        }
    };

    var lastDirection = 'down';
    $('.track-scroll').addClass(lastDirection);

    var cpt = 0;
    $('.nav-interne .navscroll .item:not(.landing-scroll) a').each(function (el) {
        $(this).attr('data-addtop', 2);
        $(this).attr('data-ancre-slide', cpt);

        var _selfA = this;
        var _selfSlider = $(this).parents('.slick-navscroll');
        var elem_to_track = $($(_selfA).attr('href')).get(0);

        if(typeof elem_to_track != 'undefined') {

            var inview = new Waypoint.Inview({
                element: elem_to_track,

                enter: function (direction) {
                    //$('.track-scroll a.active').removeClass('active');
                    $(_selfA).addClass('active');
                    var gotoSlide = $(_selfA).data('ancre-slide');
                    if(isMobileOrSmaller()){
                        _selfSlider.slick('slickGoTo', gotoSlide);
                    }
                },
                exited: function (direction) {
                    $(_selfA).removeClass('active');
                }
            })
        }

        cpt++;
    });



    if(isMobileOrSmaller()){

        $('.slick-navscroll').each(function () {
            $(this).slick({
                dots: false,
                arrows: true,
                loop: false,
                infinite: false,
                variableWidth: true,
                autoplay: false,
                autoplaySpeed: 0,
                accessibility: false
            });
        });

    }
}

/**
 * Gestion du bouton de retour vers la landing JO
 * n'apparait que si la première page visité est du type landing JO (= si l'historique de navigation est vide)
 */

// ---------- Méthodes/variables utiles à la vérification de la possibilité d'utiliser le localStorage -------------
var LSenabled = false;

var ThLocalStorage = {

    useLocalStorage: function() {
        try {
            var storage = window.localStorage,
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }
}

// ---------- /EOF -------------

var on_landing_generique  = false;

jQuery(document).ready()
{
    LSenabled = ThLocalStorage.useLocalStorage();

    // fonctionne unqiueemnt si localStorage possible
    if(LSenabled){

        // on detecte si on est sur une page landing ou non
        if($('.landing-generique').length > 0 && typeof data_btn_retour !== 'undefined') {
            console.log('On Landing Generique With BTN');
            on_landing_generique = true;
        }

        // si on vient d'une autre domaine et qu'on est sur une page landing, on active le bouton
        // si pas de bouton défini, on est pas sur une landing on_landing_generique === false
        if((document.referrer).match(document.location.origin) === null && on_landing_generique) {
            // console.log('Enable BTN Back Landing');
            localStorage.setItem('url_landingjo', document.location.href);
            localStorage.setItem('data_btn_retour',JSON.stringify(data_btn_retour));
        } else if((document.referrer).match(document.location.origin) === null) {
            // si on arrive depuis une autre page qu'une landing depuis un autre domaine, on reset le bouton
            // console.log('Disable BTN Back Landing');
            localStorage.setItem('url_landingjo', false);
            localStorage.setItem('data_btn_retour', false);
        }

        // récupération du bouton
        var btn_url_landingjo = localStorage.url_landingjo;

        // si y a un bouton, on place l'infos dans le html et affiche le bouton
        if(typeof btn_url_landingjo != 'undefined' && btn_url_landingjo != "false" && btn_url_landingjo != false && btn_url_landingjo != "undefined" && on_landing_generique === false) {

            var btn_data = JSON.parse(localStorage.data_btn_retour);

            $('#retour-landing').removeClass('hide');
            $('#retour-landing').addClass('retour');
            $('#retour-landing').attr('href',btn_url_landingjo);
            $('#retour-landing .page-title').text(btn_data.title);
            $('#retour-landing figure').replaceWith($(btn_data.img));

            // au clic sur le bouton close, on masque et désactive celui-ci
            $('#retour-landing .close').click(function(e){
                e.preventDefault();
                $('#retour-landing').addClass('hide');
                localStorage.setItem('url_landingjo', false);
                localStorage.setItem('data_btn_retour', false);
            });
        }


    }
}


$('.nav-interne .jo-nav-lien a').on('click', function () {
    var id = $(this).attr('href');

    $(id).find($("a[href], button, select, [tabindex]:not(span)")).first().focus();
});