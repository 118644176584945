tarteaucitron.init({
    "privacyUrl": "", /* Privacy policy url */
    "hashtag": "#rgpd", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */
    "orientation": "bottom", /* Banner position (top - bottom) */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */
    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "DenyAllCta": true, /* Show the deny all button */
    "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */
    "removeCredit": true, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
    "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */
    //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
    "readmoreLink": "", /* Change the default readmore link */
    "mandatory": true, /* Show a message about mandatory cookies */
});

// Ajouter ici tous les services susceptibles de déposer des cookies (cf https://opt-out.ferank.eu/fr/install/)
// pour les services nécéssitants des modifs du code html, se referer à la classe ThuriaAuCitron

if (typeof sendinblueOnPage !== 'undefined'){
    tarteaucitron.user.tawktoId = '60951dfa185beb22b30b0822/1f537q6nl';
    (tarteaucitron.job = tarteaucitron.job || []).push('tawkto');
}

// Matomo
// tarteaucitron.user.matomoId = 13;
// tarteaucitron.user.matomoHost = 'https://matomo.dev-thuria.com/';
// (tarteaucitron.job = tarteaucitron.job || []).push('matomo');


// analytics
if(document.location.origin.match('congres')){
    tarteaucitron.user.googletagmanagerId = 'GTM-M69MD5R';
}else{
    tarteaucitron.user.googletagmanagerId = 'GTM-TS3FDF3';
}
(tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');

if(document.location.origin.match('congres')){
    tarteaucitron.user.analyticsUa = 'UA-103414606-2';
    (tarteaucitron.job = tarteaucitron.job || []).push('analytics');

}else{
    tarteaucitron.user.analyticsUa = 'UA-103414606-1';
    (tarteaucitron.job = tarteaucitron.job || []).push('analytics');
}

// médias
(tarteaucitron.job = tarteaucitron.job || []).push('dailymotion');

function CitronVideoHtml(id, plateforme, autoplay, mute) {
    return '<div class="tac_player ' + plateforme + '_player" videoID="' + id + '" width="1280px" theme="dark" height="auto" showinfo="0" controls="0" rel="0" autoplay=' + autoplay + '" mute="' + mute + '"></div>';
}
