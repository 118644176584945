/*
        EGALISE EN HAUTEUR DES BLOCKS

        Mettre data-egalize en attr sur le parent
        Mettre la cible en tant que valeur
        Exemple (egalize tous les li):
        <ul data-egalize="li">

        ATTENTION: Modification effectué dans la fonction egalizeBlock afin d'éviter d'avoir la même hauteur des articles dans le blog en version tablette et mobile. En effet il y a des articles
         avec un hauteur de 474px ce qui force les articles plus petits à avoir la même hauteur et donc beaucoup d'espace blanc.
 */



function egalizeAll(){
    $('[data-egalize]').each(function(){

        var _self = this;
        var target = $(_self).data('egalize');

        egalizeBlock(_self,target);

        $('img',_self).on('load',function(){
            egalizeBlock(_self,target);
        });
    });
}
function egalizeBlock(elem,target){

    var maxHeight = 0;

    $(target, elem).each(function(){
        $(this).css('min-height','auto');
        if(maxHeight < $(this).height()){
            maxHeight = $(this).height();
        }
    });

    // Si on est dans la page archive et que la résolution de l'écran et majeure de 1024px (tablette landscape) alors on force les blocs à avoir la même hauteur, sinon on touche à rien

    if(!$(target).closest(".archive").length ) {
       if($(window).width() > 1024) {
           $(target, elem).css('min-height',(maxHeight+1)+'px');
        }
    }


}

/*
    Previen de certains bug
 */
egalizeAll();
setTimeout(egalizeAll,250);
setTimeout(egalizeAll,750);
setTimeout(egalizeAll,1500);

$(window).on('resize',egalizeAll);