
function initWidgetsAllianceReseaux(){
    var $elems = $('[data-code-ui]');

    if($elems.length > 0){

        $elems.each(function(){

            // WIDGET DANS LES PAGES
            var data = {
                idPanier: "JR4nJSU",
                idIntegration: 1039,
                langue: $(this).attr('data-langue'),
                ui: $(this).attr('data-code-ui'),
            };
            var widgetProduit = AllianceReseaux.Widget.Instance("Produit",data);

            if(document.location.hash){
                var parts = document.location.hash.split('date=');
                if(parts.length > 1){
                    var options = parts[1].split(',');

                    if(options.length == 3){
                        var date = new Date(options[0]*1000);

                        var day = (date.getDate());
                        if(day < 10) {
                            day = '0'+day;
                        }
                        var month = (date.getMonth()+1);
                        if(month < 10) {
                            month = '0'+month;
                        }
                        
                        var dateFormated = date.getFullYear()+'-'+month+'-'+day;
                        console.log('AUTO COMPLETE OPEN SYSTEM : ');
                        console.log('Date : ',dateFormated);
                        console.log('Durée : ',options[1]);
                        console.log('Nombre adulte : ',options[2]);
                        console.log('CODE UI : ', data.ui);
                        widgetProduit.PreApp("dispo.debut", dateFormated );
                        widgetProduit.PreApp("dispo.duree", parseInt(options[1]) );
                        widgetProduit.PreApp("dispo.nbAdultes", parseInt(options[2]) );
                        widgetProduit.PreApp("dispo.rechercher", true );

                    }

                }
            }


            widgetProduit.Initialise();

        });
    }


    var lgPanier = 'fr';
  if($('html').attr('lang') == 'en-GB'){ lgPanier = 'uk';}
  if($('html').attr('lang') == 'nl-NL'){ lgPanier = 'nl';}

    // PANIER
    var widgetPanier = AllianceReseaux.Widget.Instance( "Panier", { idPanier:"JR4nJSU", idIntegration:1039, langue:lgPanier } );
    widgetPanier.Initialise();

    widgetPanier.Evt("panier.popIn.surAffichage",function(evt,data,opts){
        console.log('SUR AFFICHAGE');
        console.log(evt);
        console.log(data);
        console.log(opts);

        var codeUi = data.ui;

        if(codeUi.match(/^(CF|CP|CA|CG)\-/)){
            codeUi = codeUi.substring(3);
        }
        parts = codeUi.split('-');
        codeUi = parts[0]+'-'+parts[1];
        crossSellingUpdatePanier(codeUi,data.domInclusion);
    });
    //checkPanierIsInit();
}


function checkPanierIsInit(){
    if($('#widget-panier .EnteteNbArticles').html().length < 20){
        setInterval(updatePanierIcon,1000);
        updatePanierIcon();
    }
    setTimeout(checkPanierIsInit,100);
}

function updatePanierIcon(){
    $('#widget-panier .EnteteNbArticles').text($('#widget-panier .EnteteNbArticles').text().replace('(','').replace(')',''));
}

function checkIfAllianceInit(){
    if(typeof AllianceReseaux != 'undefined'){
        setTimeout(initWidgetsAllianceReseaux,100);
    }else{
        setTimeout(checkIfAllianceInit,100);
    }
}
checkIfAllianceInit();



function crossSellingUpdatePanier(code_ui,el){

    jQuery.post(
        document.location.origin+'/wp-admin/admin-ajax.php',
        {
            'action': 'apidaecrossselling',
            'code_ui': code_ui
        },
        function(response){
            console.log(response);
            if(response){

              var lgPanier = 'fr';
              if($('html').attr('lang') == 'en-GB'){ lgPanier = 'uk';}
              if($('html').attr('lang') == 'nl-NL'){ lgPanier = 'nl';}

              var html = '<div class="crossselling-items clearfix"><h2>On vous propose aussi</h2>';

              if(lgPanier == 'uk') {
                 html = '<div class="crossselling-items clearfix"><h2>We also offer you</h2>';
              }

                $.each(response,function(k,item){
                    html += '<a href="'+item.link+'"><figure class="ratio-16-9"><img src="'+item.photo+'" alt="" width="224" height="126"/></figure><h3>'+item.titre+'</h3></a>';
                });

                html += '</div>';

                console.log(el);
                $(el).append(html).show();


                var maxHeight = 0;
                $('.crossselling-items a',el).each(function(){
                    var oh = $(this).outerHeight();
                    if(maxHeight < oh){
                        maxHeight = oh;
                    }
                });
                $('.crossselling-items a',el).height(maxHeight);
            }
        },'json'
    );

}

// On ajoute un attribut aria-label sur le panier, pour pouvoir vocaliser son contenu.
// Le setTimeout est nécessaire pour donner le temps à opensystem d'injecter le texte dynamiquement dans le DOM,
// comme ça on peut ensuite le récupérer.

$(document).ready(function() {
   setTimeout(function() {
       var panier = $('#panier-ecran');
       var contenuPanier = $('#panier-ecran .ResumeBas .nbarticles').text();

       panier.attr('aria-label', contenuPanier);
   }, 2000);

});