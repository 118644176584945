/* --------------------------- */
/* --------------------------- */

/* SCRIPT POUR OWL CAROUSEL OPACIFY */

/* --------------------------- */
/* --------------------------- */

function opacifySlider() {
    $('.owl-opacify').on('translated.owl.carousel', function () {
        var $el = $(this);
        opacifyOffSlide($el);
        $el.removeClass('translate');

    }).on('translate.owl.carousel drag.owl.carousel', function () {
        $(this).addClass('translate');
    }).on('initialized.owl.carousel', function () {
        var $el = $(this);
        opacifyOffSlide($el);
    });
}
opacifySlider();

function opacifyOffSlide($el) {
    var elOffset = $el.offset();
    var left = elOffset.left;
    var width = $el.width();
    var right = left + width;

    var slides = [];

    $('.owl-item', $el).each(function () {
        $slide = $(this);
        var o = $slide.offset();
        var w = $slide.width();

        if (o.left < left) {
            slides.push(this);
        }
        if (o.left + w > right) {
            slides.push(this);
        }
    }).removeClass('opacify');

    $(slides).addClass('opacify');

}
// End Change comportement OwlCarousel


/**
 * prend en charge automatiquement les options suivantes en balise data :
 *
 * items,
 * loop,
 * margin, center, autowidth, autoheight,
 * nav, dots,
 * autoplay, autoplayTimeout, autoplayHoverPause, autoplaySpeed
 *
 */

$('.owl-header').each(function () {

    var _self = $(this);

    var options = {
        navText: ["<button tabindex='0'><span class='icon-ico-arrow-left' aria-hidden='true'></span><span class='sr-only'>Voir la précédente slide</span></button>", "<button tabindex='0'><span" +
        " class='icon-ico-arrow-right'" +
        " aria-hidden='true'></span><span class='sr-only'>Voir la slide suivante</span></button>"],
        items: 1,
        loop: true,
        margin: 0,
        nav: true,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,

    };

    var data = _self.data();

    $.each(data, function (key, data) {

        if (key.match(/(items|loop|margin|center|autoWidth|autoheight|nav|dots|autoplay|autoplayspeed)/gi)) {
            options[key] = data;
        }

    });

    $('.wrapper-progressbar span').stop().css('width', 0).animate({width: '100%'}, 4000);
    _self.owlCarousel(options);
    _self.on('changed.owl.carousel', function (event) {
        // Do something
        $('.wrapper-progressbar span').stop().css('width', 0).animate({width: '100%'}, 4000);

        thAnimParse(_self);
    });
})


$('.bloc-agenda .owl-cards').each(function () {

    if ($('.item',this).length > 0) {

        $(this).addClass('parsed-carousel');
        var _self = $(this);

        var options = {
            loop: false,
            margin: 20,
            dots: true,
            nav: true,
            items: 4,
            autoWidth: true,
            navText: ["<button tabindex='0'><span class='icon-ico-arrow-left' aria-hidden='true'></span><span class='sr-only'>Voir la précédente slide</span></button>", "<button tabindex='0'><span" +
            " class='icon-ico-arrow-right'" +
            " aria-hidden='true'></span><span class='sr-only'>Voir la slide suivante</span></button>"],
            responsive: {
                1280: {
                    items: 3
                },
                768: {
                    items: 2
                }
            }

        };

        var data = _self.data();

        $.each(data, function (key, data) {

            if (key.match(/(items|loop|margin|center|autoWidth|autoheight|nav|dots|autoplay|autoplayspeed)/gi)) {
                options[key] = data;
            }

        });

        _self.on('initialized.owl.carousel', function (event) {
            // Do something
            thAnimParse(_self);
        });
        // console.log(_self, options);

        _self.owlCarousel(options);
    }

});
$('.owl-cards:not(.parsed-carousel)').each(function () {

    if ($('.item, .card-apidae',this).length > 0) {
        var _self = $(this);

        var options = {
            loop: false,
            margin: 20,
            dots: true,
            nav: true,
            items: 4,
            autoWidth: true,
            navText: ["<button tabindex='0'><span class='icon-ico-arrow-left' aria-hidden='true'></span><span class='sr-only'>Voir la précédente slide</span></button>", "<button tabindex='0'><span" +
            " class='icon-ico-arrow-right'" +
            " aria-hidden='true'></span><span class='sr-only'>Voir la slide suivante</span></button>"],
        };

        var data = _self.data();

        $.each(data, function (key, data) {

            if (key.match(/(items|loop|margin|center|autoWidth|autoheight|nav|dots|autoplay|autoplayspeed)/gi)) {
                options[key] = data;
            }

        });

        _self.on('initialized.owl.carousel', function (event) {
            // Do something
            thAnimParse(_self);
        });

        // console.log(_self, options);
        _self.owlCarousel(options);
    }

});


$('.owl-slider').each(function () {

    var _self = $(this);

    var options = {
        items: 1,
        loop: true,
        singleItem: true,
        margin: 0,
        nav: true,
        dots: true,
        navText: ["<button tabindex='0'><span class='icon-ico-arrow-left' aria-hidden='true'></span><span class='sr-only'>Voir la précédente slide</span></button>", "<button tabindex='0'><span" +
        " class='icon-ico-arrow-right'" +
        " aria-hidden='true'></span><span class='sr-only'>Voir la slide suivante</span></button>"],
        autoplay: false,
        autoplayTimeout: 4000,
    };

    var data = _self.data();

    $.each(data, function (key, data) {

        if (key.match(/(items|loop|margin|center|autoWidth|autoheight|nav|dots|autoplay|autoplayspeed)/gi)) {
            options[key] = data;
        }

    });

    _self.on('initialized.owl.carousel', function (event) {
        // Do something
        // $('.owl-stage', _self).attr('data-anim', 'bottom-stack');
    });

    // console.log(_self, options);
    _self.owlCarousel(options);

});


$('.owl-social').each(function () {

    var _self = $(this);

    var options = {
        responsive: {
            0: {
                items: 1,
                margin: 20,
            },
            768: {
                items: 2,
                margin: 20
            },
            1024: {
                items: 3,
                margin: 40
            }
        },
        loop: true,
        singleItem: true,
        nav: true,
        dots: true,
        navText: ["<button tabindex='0'><span class='icon-ico-arrow-left' aria-hidden='true'></span><span class='sr-only'>Voir la précédente slide</span></button>", "<button tabindex='0'><span" +
        " class='icon-ico-arrow-right'" +
        " aria-hidden='true'></span><span class='sr-only'>Voir la slide suivante</span></button>"],
        autoplay: false,
        autoplayTimeout: 4000,
    };

    var data = _self.data();



    $.each(data, function (key, data) {

        if (key.match(/(items|loop|margin|center|autoWidth|autoheight|nav|dots|autoplay|autoplayspeed)/gi)) {
            options[key] = data;
        }

    });


    _self.owlCarousel(options);
});



// On bouge les boutons de navigation avant le slider pour les rendre immediatement accessibles au clavier

$('.owl-carousel .owl-nav').each(function() {
    $(this).parent().prepend($(this));
});




