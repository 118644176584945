/**
 * Permet au focus d'activer le credit
 * @type {{init: th_rgaa_image.init, selector: {credit: string}}}
 */
var th_rgaa_image = {
    _selector: {
        credit: ".credits"
    },

    init: function() {
        document.addEventListener("DOMContentLoaded", function() {
            $("a " + th_rgaa_image._selector.credit + "+figure").each(function() {
                th_rgaa_image.parse(this);
            })
        })
    },

    parse: function(el) {
        var container = $(el).parents("a").get(0);

        if (container) {
            // Focus retiré sur le credit
            var credit = container.querySelector(th_rgaa_image._selector.credit);
            credit.removeAttribute("tabindex");

            // Ajout de la classe pour ouvrir le focus
            container.classList.add("credit-focus");
        }
    }
};