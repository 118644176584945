// Le script suivant permet de récupérer l'attribut title de l'iframe rentrée dans le BO et affiché au niveau de l'élément ".item"

$(document).ready(function () {
    setTimeout(function () {
        var instagramIframe = $('.bloc-instagram .item');

        instagramIframe.each(function () {
            var instagramIframeTitle = $(this).attr('title');

            $(this).find('iframe').attr('title', instagramIframeTitle);
        });
    }, 2500);
});
