$(function()
{
	ar_menu();
});

function ar_menu()
{
	// mobile sidebar menu
	$('.th-menu').click(function()
	{
		if(!$(this).hasClass('open'))
		{
			$(this).addClass('open');
			$('nav').addClass('show');
			$('#header').addClass('menu-open');
			$('.social-share').css('opacity','0');
			$('.retour').css('opacity','0');
			$('body').css('overflow','hidden');
		}
		else
		{
			$(this).removeClass('open');
			$('nav').removeClass('show');
			$('#header').removeClass('menu-open');
			$('body').css('overflow','initial');
			$('.social-share').css('opacity','1');
			$('.retour').css('opacity','1');
			$('body').removeClass('sub-menu-open');
		}
	});
}


// Ouverture - Fermeture de la DropDown Menu en accordéon
var $lienNav = $('.wrapper-nav nav > ul > li.has-sub-menu > a');
$lienNav.each(function(){
	$(this).on('click', function (e) {
		e.preventDefault();
		var $otherSubOpened = $('.wrapper-nav nav > ul > li.has-sub-menu.sub-menu-open').first();
		var $parent = $(this).parents('.has-sub-menu').first();
		if ($otherSubOpened[0] != $parent[0]) {
			console.log('Comparison:', $otherSubOpened[0] != $parent[0])
			if ($otherSubOpened) {
				$otherSubOpened.removeClass('sub-menu-open');
				$otherSubOpened.removeClass('active');
			}
			setTimeout(function () {
				$('body').addClass('sub-menu-open');
				$parent.addClass('sub-menu-open');
				$parent.addClass('active');
			}, 50);
		}else{
			$otherSubOpened.removeClass('sub-menu-open');
			$otherSubOpened.removeClass('active');
			$('body').removeClass('sub-menu-open');
			$otherSubOpened = null;
		}
	});
});


$('.cross').on('click',function(){
	$('.wrapper-nav ul li.has-sub-menu').removeClass('active');
	$('body').removeClass('sub-menu-open');
});

// Quand on click sur l'îcone de recherche dans le menu, on ouvre la search bar
$('.circle-search').on('click', function () {
	$('#header').toggleClass('search-open');
	$('.wrapper-search form input').focus();
});

// Au click sur la barre présente dans la couverture de page, on ouvre la barre de recherche
// Note: Cet id est assigné au bouton de la barre seulement si on n'est pas dans la version française
$('#search-opener-foreign-language').on('click', function () {
	$('#header').toggleClass('search-open');
	$('#barre-recherche input').focus();
});

// Au clique sur .shadow on peut refermer la searchbar
$('.shadow').on('click', function () {
	if ($('#header').hasClass('search-open')) {
		$('#header').removeClass('search-open');
	}
});

/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function (e) {
	var st = $(this).scrollTop();

	if (st > 100) {
		$("body").addClass("is-scrolled");
		$('.social-share').addClass('fadein');
		$('.retour').addClass('fadein');
	}
	else {
		$("body").removeClass("is-scrolled");
		$('.social-share').removeClass('fadein');
		$('.retour').removeClass('fadein');
	}
});




var lastscrolltop = 0;
var lastIsDirTop = 0;

document.addEventListener('scroll',function(){
	var st = $(document).scrollTop();
	if(st<lastscrolltop && lastIsDirTop == 0){
		lastIsDirTop = 1;
		$(document.body).addClass('scrolldir-top',true);
	}
	if(st>lastscrolltop && lastIsDirTop == 1){
		lastIsDirTop = 0;
		$(document.body).removeClass('scrolldir-top',true);
	}
	lastscrolltop = st;
});

$('#header-v2 .lang > a, #header .lang > a').on('click', function () {
	$(this).parent().toggleClass('open');
});

$(".wrapper-menu-duo ul li").each(function () {
	$(this).find('.acces-premier-niveau').on('click', function () {
		var parentMenuId = $(this).attr("data-parent-menu");

		$(".menus-left ul:first-of-type li").each(function () {
			var parentButton = $(this).find('button');
			var parentButtonId = '#' + parentButton.attr('id');
			console.log("parentButton", parentButton.attr("id"));
			console.log("parentmenuid", parentMenuId);
			if (parentButtonId === parentMenuId) {

				parentButton.attr("aria-expanded", "false");
			}
		});
	});
});


