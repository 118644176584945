if($('.bloc-faq').length > 0){

    $('.bloc-faq').each(function(){

        var _this = $(this);
        var $button = $('.tab-buttons button', _this);
        var $eachWrapperAccordeon = $('.wrapper-accordeons', _this);

        setTimeout(function(){
            $('.accordeon').each(function () {
                $(this).trigger('forcerefresh');
            });
        }, 200);

        $button.each(function (key, el) {
            $(this).on('click', function (e) {
                $button.removeClass('active');
                $(this).addClass('active');
                $($eachWrapperAccordeon.get(key)).show();


                $('.accordeon').removeClass('open');

                setTimeout(function(){
                    $('.accordeon').each(function () {
                        $(this).trigger('forcerefresh');
                    });
                }, 200);
            });

            $(this).on('blur', function (e) {
                $button.removeClass('active');
                $(this).addClass('active');
                $($eachWrapperAccordeon.get(key)).show();


                $('.accordeon').removeClass('open');

                setTimeout(function(){
                    $('.accordeon').each(function () {
                        $(this).trigger('forcerefresh');
                    });
                }, 200);
            });
        });

    });

}