var thConfig = {

    singleSITBreakpoint: 768,
    tabletPortraitBreakpoint: 980,
    tabletPaysageBreakpoint: 1024,
    mobileBreakpoint: 600,
    desktopBreakpoint: 1280,

    debug: true,

    algolia: {
        key: thGlobalDefaults.algolia.key,
        id: thGlobalDefaults.algolia.id
    },


    //4svfqb5vfsqg9xwbjqlow3kh   //   choisirgeoportail
    map: {

        init: {
            maps_class: '.maps:not(.no-autoload):not(.initialized)',
            tileLayerUrl: "https://wxs.ign.fr/{accessToken}/geoportail/wmts?" +
                "&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0" +
                "&STYLE=normal" +
                "&TILEMATRIXSET=PM" +
                "&FORMAT=image/png"+
                "&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2"+
                "&TILEMATRIX={z}" +
                "&TILEROW={y}" +
                "&TILECOL={x}",
            accessToken: 'cartes',
            tileLayerOptions: {
                attribution: '',
                maxZoom: 16,
                accessToken: 'cartes'
            }
        }

    }

};