// Afficher une date

$('.frm_date_long').each(function(){
    var picker = new Pikaday({ field: this,format: 'DD/MM/YYYY',  firstDay: 1});
});

//$('.form-reserve .frm_date').each(function(){
//    var picker = new Pikaday({ field: this,format: 'DD/MM/YY' });
//});

if($('.bloc-reservationv1 .frm_date').length > 0){
    var firstFieldDate = $('.form-reserve-home .frm_date').first()[0];
    var lastFieldDate = $('.form-reserve-home .frm_date').last()[0];
    var firstPikaday = new Pikaday({ field: firstFieldDate,format: 'DD/MM/YY', });
    var lastPikaday = new Pikaday({ field: lastFieldDate,format: 'DD/MM/YY', });
    if(firstPikaday.getDate()){
        lastPikaday.setMinDate(firstPikaday.getDate());
    }

    $('.form-reserve-home .frm_date').first().on('change',function(){
        lastPikaday.setMinDate(firstPikaday.getDate());
        if(!lastPikaday.getDate() || firstPikaday.getDate().getTime() > lastPikaday.getDate().getTime()){
            lastPikaday.setDate(new Date(firstPikaday.getDate().getTime()+1000*60*60*24));
        }
    });

}


