class blockReservationV2 {

    constructor(form_selector) {
        if($(form_selector).length == 0 ) {
            return;
        }

        this.form_selector = form_selector;
        this.firstDatePikaday;
        this.lastDatePikaday;
        this.initPikaday();
        this.manageCurrentState($('option',this.form_selector).first());
        this.initFormEvent();
    }

    initPikaday () {
        if($('.frm_date', this.form_selector).length > 0) {
            var firstFieldDate = $('.frm_date', this.form_selector).first()[0];
            var lastFieldDate = $('.frm_date', this.form_selector).last()[0];

            //initisalisation des Pikaday
            this.firstDatePikaday = new Pikaday({field: firstFieldDate, format: 'DD/MM/YY', firstDay: 1});
            this.lastDatePikaday = new Pikaday({field: lastFieldDate, format: 'DD/MM/YY', firstDay: 1});

            // la date mini du deuxième doit être postérieure à celle du 1er
            if (this.firstDatePikaday.getDate()) {
                this.lastDatePikaday.setMinDate(this.firstDatePikaday.getDate());
            }

            // quand on change la date du premier, on change la date du 2nd
            $('.frm_date', this.form_selector).first().on('change', function () {
                this.lastDatePikaday.setMinDate(this.firstDatePikaday.getDate());
                if (! this.lastDatePikaday.getDate() || this.firstDatePikaday.getDate().getTime() >  this.lastDatePikaday.getDate().getTime()) {
                    this.lastDatePikaday.setDate(new Date(this.firstDatePikaday.getDate().getTime() + 1000 * 60 * 60 * 24));
                }
            });
        }
    }

    initFormEvent () {
        var _self = this;
        $('select',_self.form_selector).on('change',function () {
            var $current_option = $('option[value="'+ $(this).val() +'"]');
            _self.manageCurrentState($current_option);
        })
    }

    // Actualise le comportement des pikadays et du sélecteur du nombre de personnes en fonction de l'option choisie
    manageCurrentState ($current_option) {
        var limit14 = parseInt($current_option.data('jslimit14'));
        var hide_nbpers = parseInt($current_option.data('jshidenbpers'));

        if(hide_nbpers) {
            this.hideNbPers();
        } else {
            this.showNbPers();
        }

        if(limit14) {
            this.limitDays();
        } else {
            this.unLimitDays();
        }
    }

    limitDays () {
        var j14 = new Date().getTime() + 14 * 24 * 60 * 60 * 1000;
        this.lastDatePikaday.setMaxDate(new Date(j14));
        this.firstDatePikaday.setMaxDate(new Date(j14));
    }

    unLimitDays () {
        this.lastDatePikaday.setMaxDate(false);
        this.firstDatePikaday.setMaxDate(false);
    }

    hideNbPers () {
        $('#nombre-personnes',this.form_selector).hide();
    }

    showNbPers () {
        $('#nombre-personnes',this.form_selector).show();
    }
}

blocREsaHebergement = new blockReservationV2('#bloc_resa_hebergement');
blocREsaActivite = new blockReservationV2('#bloc_resa_activite');

