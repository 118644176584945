function loadEmailAdresseAjax(e){
    e.preventDefault();
    e.stopPropagation();
    var url;

    if (this.getAttribute("data-link")) {
        url = this.getAttribute("data-link");
    } else {
        url = document.location.href;
    }

    $.get(url,{'get-email':1},function(response){
        if(response && response.email && response.email != ''){
            $('a[href="#load-mail"]').off('click');
            $('a[href="#load-mail"]').attr('href','mailto:'+response.email).text(response.email);
        }
    },'json');
}

if($('a[href="#load-mail"]').length > 0){
    $('a[href="#load-mail"]').on('click',loadEmailAdresseAjax);
}


function loadEmailAdresseGestionAjax(e){
    e.preventDefault();
    e.stopPropagation();
    var url;

    if (this.getAttribute("data-link")) {
        url = this.getAttribute("data-link");
    } else {
        url = document.location.href;
    }

    $.get(url,{'get-email-gestion':1},function(response){
        if(response && response.email && response.email != ''){
            $('a[href="#load-mail-gestion"]').off('click');
            $('a[href="#load-mail-gestion"]').attr('href','mailto:'+response.email).text(response.email);
        }
    },'json');
}

if($('a[href="#load-mail-gestion"]').length > 0){
    $('a[href="#load-mail-gestion"]').on('click',loadEmailAdresseGestionAjax);
}