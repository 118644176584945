$('.bloc-image .btn-plus').on("click",function(){

    // On change la valeur de l'attribut aria-expanded à true ou false selon si on vient d'ouvrir ou fermer la modale
    var btnPlus =  $('.btn-plus')
    if( btnPlus.attr('aria-expanded') == 'true') {
       btnPlus.attr('aria-expanded', 'false');
    } else {
       btnPlus.attr('aria-expanded', 'true');
    }

    // On ajoute la classe open pour visualiser la modale
    $(this).parents('.caption-image').toggleClass('open');

});