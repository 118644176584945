// Classement en étoile, épis et clé vacances
$('input + label','.classement-chk').on('mousedown',function(e){
    radio = $(e.target).parent().prev()[0];
    if(radio.checked) {
        // bind event to reset state after click is completed
        $(e.target).mouseup(function() {
            // bind param, because "this" will point somewhere else in setTimeout
            // apparently if you do it immediatelly, it will be overriden, hence wait a tiny bit

            setTimeout(function() {
                $(radio).parent().find('input').first().click();
            }, 5);
            // don't handle mouseup anymore unless bound again
            $(this).unbind('mouseup');
        });
    }
});



// Moteur Expérienticielle HomePage
$('#btn-inspire, #btn-reserv').on('click',function(){
    $('.choices > a').removeClass('active');
    $(this).addClass('active');
    $('.wrap-submit').show();
});

$('#btn-inspire').on('click',function(e){
    e.preventDefault();
    $('.form-reserve').removeClass('display-form');
    $('.form-inspire').addClass('display-form');
});


$('#btn-reserv').on('click',function(e){
    e.preventDefault();
    $('.form-inspire').removeClass('display-form');
    $('.form-reserve').addClass('display-form');
});


var Ww = $(window).width();

if(Ww <= 1100){
    $('.choices > a').removeClass('active');
    $('.form-inspire, .form-reserve').removeClass('display-form');
    $('.wrap-submit').hide();
}