/*$('[data-gacat]').on('click',function(){

    var category = $(this).attr('data-gacat');
    var action = $(this).attr('data-gaaction');
    if(!action || action == ''){
        action = 'click';
    }
    var label = $(this).attr('data-galabel');

    var data = {
        'event_category': category,
        'event_label': label
    };

    console.log('GA Event',data);
    if(typeof gtag != 'undefined'){
        gtag('event', action,data);
    }

});
*/

window.ga = window.ga || function () {
    window.ga.q = window.ga.q || [];
    window.ga.q.push(arguments);
};

$('[data-gacat]').on('click',function(){

    var category = $(this).attr('data-gacat');
    var action = $(this).attr('data-gaaction');
    var label = $(this).attr('data-galabel');

    if(!action || action == ''){
        action = 'click';
    }


    var data = {
        'event_category': category,
        'event_label': label
    };

    //console.log('GA Event gaq',data);
    //window._gaq.push(['_trackEvent',category,action,label]);

    console.log('GA Event ga',data);
    ga('send', 'event', category, action, label);



    /*
    if(typeof  window._gaq != 'undefined'){
        console.log('GA Event gaq',data);
        window._gaq.push(['_trackEvent',category,action,label]);
    }else if(typeof ga != 'undefined'){
        console.log('GA Event ga',data);
        ga('send', 'event', category, action, label);
    }else if(typeof gtag != 'undefined'){
        console.log('GA Event gtag',data);

        gtag('event', action,data);
    }else if(typeof dataLayer != 'undefined'){
        console.log('GA Event datalayer',data);
        dataLayer.push('event', action,data);
    }else{
        console.log('GA Event no',data);
    }
    */

});