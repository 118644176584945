th_maps.onLoad(function () {
    th_maps.addMarkerIcon('marker', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/clermont-rgaa/assets/images/markers/marker-2x.png',
        shadowUrl: null,
        iconSize: [40, 50],
        iconAnchor: [20, 50]
    });

    th_maps.addMarkerIcon('marker-hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/clermont-rgaa/assets/images/markers/marker-hover-2x.png',
        shadowUrl: null,
        iconSize: [40, 50],
        iconAnchor: [20, 50]
    });

    th_maps.addMarkerIcon('marker-locate', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/clermont-rgaa/assets/images/markers/marker-locate-2x.png',
        shadowUrl: null,
        iconSize: [40, 50],
        iconAnchor: [20, 50]
    });

    L.Icon.Default.prototype.options.iconUrl = '' + document.location.origin + '/wp-content/themes/clermont-rgaa/assets/images/markers/marker-2x.png';
    L.Icon.Default.prototype.options.shadowUrl = null;
    L.Icon.Default.prototype.options.shadowSize = null;
    L.Icon.Default.prototype.options.iconSize = [20, 26];
    L.Icon.Default.prototype.options.iconAnchor = [10, 26];
});


function generateContentWindow(img, title) {
    var content = '<div id="map-inte-container">' +
        '<div class="map-inte-content">' +
        '<div class="map-inte-header"><figure><img src="' + img + '" width="260" height="160" alt="Titre image" class="fit-cover img-carte-inte" /></figure></div>' +
        '<div class="map-inte-content-text"><h2 class="title-map-inte">' + title + '</h2>' +
        '<a href="#" class="basic-link">En savoir plus</a>' +
        '</div></div></div>';
    return content;
}

var map;
var GetPosition = document.getElementById('cible');
var bounds;
var markerTargetCurrentGeo;
var positionDejaprise;
var macartelisting;

/* MAP POUR LE LISTING DES HEBERGEMENTS */
function callbackMapListing(macarte) {


    if (typeof listingSit === "undefined") {
        setTimeout(function () {
            callbackMapListing(macarte);
        }, 100);
        return true;
    }

    markerTargetCurrentGeo = th_maps.createMarkerTarget();

    macartelisting = macarte;


    if ($('input[name="lat"]').val() != '') {
        pos = {
            lat: $('input[name="lat"]').val() * 1,
            lng: $('input[name="lng"]').val() * 1
        };


        // ---------------------------- ---------------------------- ---------------------------- DOIT ÊTRE MIS A JOUR
        // markerTargetCurrentGeo.setPosition(pos);
        // markerTargetCurrentGeo.setMap(macarte);
        // macarte.setCenter(pos);
        // macarte.setZoom(13);
        macartelisting.flyTo(pos, 13);
        // ---------------------------- ----------------------------
    }


    $('.btn-loc a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $self = $(this);
        $self.attr('id', 'geoloc');
        $self.parent().toggleClass('active');
        var positionDejaprise = false;
        setTimeout(function () {
            if ($self.parent().hasClass('active')) {
                console.log('Activation de la geoloc');

                if (navigator.geolocation) {

                    if (positionDejaprise !== false) {
                        console.log('Geoloc déjà récupéré, affichage');


                        var pos = positionDejaprise;
                        $('input[name="lat"]').val(pos.lat);
                        $('input[name="lng"]').val(pos.lng);

                        // ---------------------------- ---------------------------- ---------------------------- DOIT ÊTRE MIS A JOUR
                        // markerTargetCurrentGeo.setPosition(pos);
                        // markerTargetCurrentGeo.setMap(macarte);
                        // macarte.setCenter(pos);

                        macarte.flyTo(pos);
                        markerTargetCurrentGeo.setLatLng(pos);
                        macarte.addLayer(markerTargetCurrentGeo);

                        // ---------------------------- ----------------------------

                        $(listingSit._selector.form).submit();
                    } else {
                        console.log('Demande de geoloc ...');
                        navigator.geolocation.getCurrentPosition(handleGeoloc, function (e) {
                            alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
                        });
                    }

                } else {
                    alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
                }
            } else {
                console.log('Désactivation de la geoloc');

                $('input[name="lat"]').val('');
                $('input[name="lng"]').val('');
                // ---------------------------- ---------------------------- ---------------------------- DOIT ÊTRE MIS A JOUR
                // markerTargetCurrentGeo.setMap(null);
                macarte.removeLayer(markerTargetCurrentGeo);
                // ---------------------------- ----------------------------
                $('.page-listing form').submit();
            }
        }, 100);

    });


    listingSit.updateCarteContent(macarte, function (macarte, bounds) {

        $('.action-map a').off('click').on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('active');
            $('.listing-map').toggleClass('active');
            $('.wrapper-facette').toggleClass('active');
            $('.wrapper-listing').toggleClass('active');
            // macarte.invalidateSize();

            setTimeout(function () {
                stickySidebar.updateSticky();
                macarte.invalidateSize();
            }, 300);

        });

        $('.wrapper-listing').off('click').on('click', function () {
            if ($(this).hasClass('active')) {
                $('.action-map a').parent().removeClass('active');
                $('.wrapper-facette').removeClass('active');
                $('.listing-map').removeClass('active');
                $('.wrapper-listing').removeClass('active');

                stickySidebar.updateSticky();
                setTimeout(function () {
                    stickySidebar.updateSticky();
                }, 100);
                setTimeout(function () {
                    stickySidebar.updateSticky();
                }, 200);
                setTimeout(function () {
                    stickySidebar.updateSticky();
                    macarte.invalidateSize();
                }, 300);
                setTimeout(function () {
                    stickySidebar.updateSticky();
                }, 400);
                setTimeout(function () {
                    stickySidebar.updateSticky();
                }, 500);

            }
        });
    });
}

function handleGeoloc(position) {
    console.log('Géoloc récupéré, affichage');

    var pos = {lat: position.coords.latitude, lng: position.coords.longitude};

    positionDejaprise = pos;
    $('input[name="lat"]').val(pos.lat);
    $('input[name="lng"]').val(pos.lng);

    macartelisting.flyTo(pos);
    markerTargetCurrentGeo.setLatLng(pos);
    macartelisting.addLayer(markerTargetCurrentGeo);

    $(listingSit._selector.form).submit();

    $('.page-listing form').submit();
}

/* MAP POUR LE LISTING DE LA PAGE EVENEMENT */
function callbackMapListingEvent(macarte) {
    console.log(macarte);

    // var bounds = new L.maps.LatLngBounds();
    var markerlist = [];

    $('.bloc-listing > .col-50').each(function () {
        var lat = $(this).attr('data-lat');
        var lng = $(this).attr('data-lng');

        if (lat && lng) {
            var geo = {
                lat: parseFloat(lat.replace(/,/g, '.')),
                lng: parseFloat(lng.replace(/,/g, '.'))
            };

            if (geo.lat && geo.lng) {
                var marker = th_maps.createMarker(macarte, geo, 'marker');
                markerlist.push(marker);

                th_maps.createInfoWindow('<a target="_blank" href="' + $(this).attr('href') + '" id="map-inte-container">' +
                    '<div class="map-inte-content">' +
                    '<div class="map-inte-header"><span class="event-date"><time datetime="2018-06-19">' + $('time', this).text() + '<span>' + $('time > span', this).text() + '</span></time></span><figure' +
                    ' class="fit-cover"><img src=' + $('img', this).attr('src') + ' width="260" height="160" alt=' + $('img', this).attr('alt') + ' class="fit-cover"' +
                    ' /></figure></div>' +
                    '<div class="map-inte-content-text"><div class="wrapper-rate"><span class="location">' + $('.location', this).text() + '</span></div><span class="theme"><span>Typologie 1</span><span>Typologie 2</span></span><h3>' + $('h3', this).text() + '</h3>' +
                    '<span class="link">Découvrir</span>' +
                    '</div></div></a>', marker, 260);
            }
        }
    });

    macarte.zoomControl.setPosition('topright');
    // th_maps.defaultOptions.mapTypeId = L.maps.MapTypeId.ROADMAP;

    $('.action-map a').on('click', function () {
        $('.listing-map').toggleClass('active');
        $('.wrapper-facette').toggleClass('active');
        macarte.invalidateSize();
    });

    console.log(markerlist);

    groupBounds = new L.featureGroup(markerlist);
    macarte.fitBounds(groupBounds.getBounds());
}

/* MAP POUR LA PAGE DE CONTACT */
function callbackMapContact(macarte) {

    parseMapPopup(macarte);
    if (macarte.getZoom() >= 8) {
        macarte.setZoom(8);
    }

}

function callbackMapPageSingle(macarte) {
    addMapPopup(macarte);
    macarte.setZoom(13);

    $('.maps').on('classChange', function () {
        setTimeout(function () {
            macarte.invalidateSize();
        }, 250);
    });

    $('.kml').on('classChange', function () {
        setTimeout(function () {
            macarte.invalidateSize();
        }, 250);
    });
}

function generateContentWindowContact(nom, adresse, tel, horaires) {
    var content = '<div id="map-inte-container">' +
        '<div class="map-inte-content">' +
        '<div class="map-inte-content-text txt">' +
        '<address><strong>' + nom + '</strong>' + adresse + '</address>' +
        '<a href="#" class="btn">' + tel + '</a>' +
        '<span>Horaires d’ouverture</span><p>' + horaires + '</p>' +
        '<a href="#" class="link">Itinéraire</a>' +
        '</div></div></div>';
    return content;
}

function callbackMapContent(macarte) {

    var groupBounds = null;
    var markerlist = [];
    var cptMarker = 0;
    if (jsonMapsBlocInteData) {

        // var bounds = new L.maps.LatLngBounds();

        $.each(jsonMapsBlocInteData, function (k, data) {
            cptMarker++;
            {
                if (data.lat && data.lng)
                    var marker = th_maps.createMarker(macarte, {lat: (data.lat * 1), lng: (data.lng * 1)}, 'marker');
                markerlist.push(marker);

                th_maps.createInfoWindow('<a target="_blank" href="' + data.url + '" id="map-inte-container">' +
                    '<div class="map-inte-content">' +
                    '<div class="map-inte-header">' +
                    //'<div class="wrapper-price"><div class="price"><span>à partir de <span class="prix">79<span>€</span></span></span></div><div class="reserve"><span>réservez en ligne</span></div> </div>' +
                    '<figure class="fit-cover"><img src="' + data.img.url + '" width="260" height="160" alt="" class="fit-cover"' +
                    ' /></figure>' +
                    '</div>' +
                    '<div class="map-inte-content-text">' +
                    '<div class="wrapper-rate"><span class="location">' + data.city + '</span></div><h3>' + data.nom + '</h3>' +
                    '<span class="link">' + data.more + '</span>' +
                    '</div></div></a>', marker, 260);

                // bounds.extend(marker.position);
                // macarte.fitBounds(groupBounds.getBounds());
            }

        });

        groupBounds = new L.featureGroup(markerlist);
        macarte.fitBounds(groupBounds.getBounds());

        // if(cptMarker > 1){
        //     macarte.fitBounds(groupBounds.getBounds());
        // }

    }

}

function callbackMapSliderAgenda(macarte) {
    sliderMapAgenda.init(macarte);
}

function callbackMapContentRgaa(macarte) {
    var groupBounds = null;
    var markerlist = [];
    var cptMarker = 0;

    var lastMarkerHover = null;

    var $blocEl = $(macarte._container).parent('.bloc-maps');
    var $owl = $blocEl.find('.owl-carousel');

    if ($blocEl) {
        $('.card-apidae', $blocEl).each(function (k, v) {

            var card = this;

            var latlng = {lat: (this.getAttribute('data-lat')), lng: (this.getAttribute('data-lng'))};
            latlng.lat = latlng.lat.replace(',', '.') * 1;// , incompatible, puis float
            latlng.lng = latlng.lng.replace(',', '.') * 1;// , incompatible, puis float
            var marker = th_maps.createMarker(macarte, latlng, 'marker');

            var data = {
                url: $('> a', this).attr('href'),
                img: {url: $('figure img', this).attr('data-src')},
                city: $('.location', this).text(),
                nom: $('h2', this).text(),
                more: $('.link', this).text()
            };
            if (document.body.clientWidth >= 600) {
                th_maps.createInfoWindow('<a target="_blank" href="' + data.url + '" id="map-inte-container">' +
                    '<div class="map-inte-content">' +
                    '<div class="map-inte-header">' +
                    '<figure class="fit-cover"><img src="' + data.img.url + '" width="260" height="160" alt="" class="fit-cover"' +
                    ' /></figure>' +
                    '</div>' +
                    '<div class="map-inte-content-text">' +
                    '<div class="wrapper-rate"><span class="location">' + data.city + '</span></div><h3>' + data.nom + '</h3>' +
                    '<span class="link">' + data.more + '</span>' +
                    '</div></div></a>', marker, 260);
            } else {
                marker.addEventListener('click', function () {
                    $owl.trigger('to.owl.carousel', k);
                });
            }


            $('*', card).on('focus', function () {
                marker.setIcon(th_maps.markersIcons['marker-hover']);
            }).on('blur', function () {
                marker.setIcon(th_maps.markersIcons['marker']);
            });

            $(card).on('mouseenter focus', function () {
                marker.setIcon(th_maps.markersIcons['marker-hover']);
            }).on('mouseleave', function () {
                marker.setIcon(th_maps.markersIcons['marker']);
            });

            markerlist.push(marker);
        })
    }

    if (markerlist) {
        groupBounds = new L.featureGroup(markerlist);
        macarte.fitBounds(groupBounds.getBounds());
    }

    if (document.body.clientWidth < 600) {

        var $card = $($owl.find('.card-apidae').get(0)).find('>a');
        $card.addClass('active');

        var marker = markerlist[0];
        marker.setIcon(th_maps.markersIcons['marker-hover']);
        if (lastMarkerHover) {
            lastMarkerHover.setIcon(th_maps.markersIcons['marker']);
        }
        lastMarkerHover = marker;

        $owl.on('changed.owl.carousel', function (state) {

            var $card = $($owl.find('.card-apidae').get(state.item.index)).find('>a');
            $card.addClass('active');

            var marker = markerlist[state.item.index];
            marker.setIcon(th_maps.markersIcons['marker-hover']);
            if (lastMarkerHover) {
                lastMarkerHover.setIcon(th_maps.markersIcons['marker']);
            }
            lastMarkerHover = marker;
        });
    }
}

function callbackCarteInteractive(macarte) {

    var searchCtrl = L.geoportalControl.SearchEngine({
        collapsed: false,
        markerStyle: th_maps.markersIcons['marker'],
        displayAdvancedSearch: false,
    });
    macarte.addControl(searchCtrl);

    var markerTargetCurrentGeo = th_maps.createMarkerTarget();
    th_maps.geoLoc(macarte, document.getElementById('cible'), markerTargetCurrentGeo);


    $('.input-city').parents('form').on('submit', function (e) {
        e.preventDefault();
    });

    macarte.zoomControl.setPosition('bottomleft');
    searchCtrl.setPosition('topright');

    // th_maps.searchCity(macarte,$('.input-city').parents('form'),markerTargetCurrentGeo);
    //
    //
    // if (!navigator.geolocation) {
    //     GetPosition.remove();
    // }else{
    //     var handleGetPosition = function (e) {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(function (position) {
    //                 var pos = {lat: position.coords.latitude, lng: position.coords.longitude};
    //                 markerTargetCurrentGeo.setPosition(pos);
    //                 markerTargetCurrentGeo.setMap(macarte);
    //                 macarte.setCenter(pos);
    //             },function(e){
    //                 alert("Votre support ne nous permet pas de trouver votre position. Vérifiez les paramètres de localisation de votre appareil.");
    //             });
    //         }else{ GetPosition.remove(); }
    //     };
    //     GetPosition.addEventListener('click', handleGetPosition, false);
    //     GetPosition.addEventListener('touchstart', handleGetPosition, false);
    // }
    //
    //
    if (jsonMapsInteData && jsonMapsInteDataCategory) {

        var markerlist = [];
        // var bounds = new L.maps.LatLngBounds();

        $.each(jsonMapsInteData, function (categoryKey, items) {

            th_maps.addMarkerIcon('marker-cat-' + categoryKey, {
                iconUrl: jsonMapsInteDataCategory[categoryKey].markerIcon,
                iconSize: [40, 50]
            });

            $.each(items, function (k, data) {


                if (data.lat && data.lng) {
                    var marker = th_maps.createMarker(macarte, {
                        lat: (data.lat * 1),
                        lng: (data.lng * 1)
                    }, 'marker-cat-' + categoryKey, 'group-' + categoryKey);

                    markerlist.push(marker);

                    th_maps.createInfoWindow('<a target="_blank" href="' + data.url + '" id="map-inte-container">' +
                        '<div class="map-inte-content">' +
                        '<div class="map-inte-header">' +
                        //'<div class="wrapper-price"><div class="price"><span>à partir de <span class="prix">79<span>€</span></span></span></div><div class="reserve"><span>réservez en ligne</span></div> </div>' +
                        '<figure class="fit-cover"><img src="' + data.img.url + '" width="260" height="160" alt="" class="fit-cover"' +
                        ' /></figure>' +
                        '</div>' +
                        '<div class="map-inte-content-text">' +
                        '<div class="wrapper-rate"><span class="location">' + data.city + '</span></div><h3>' + data.nom + '</h3>' +
                        '<span class="link">' + jsonMapsInteDataCategory[categoryKey].more + '</span>' +
                        '</div></div></a>', marker, 260);

                    // bounds.extend(marker.position);
                }

            });

            if ($('input[value="' + categoryKey + '"]:checked', '.sort-listing').length > 0) {
                th_maps.showGroupMarker('group-' + categoryKey, macarte);
            } else {
                th_maps.hideGroupMarker('group-' + categoryKey, macarte);
            }
        });

        // macarte.fitBounds(bounds)

        groupBounds = new L.featureGroup(markerlist);
        macarte.fitBounds(groupBounds.getBounds());

        $('input[type="checkbox"]', '#carte-menu').on('change', function () {
            var _self = this;
            setTimeout(function () {
                if (_self.checked) {
                    th_maps.showGroupMarker('group-' + _self.value, macarte);
                } else {
                    th_maps.hideGroupMarker('group-' + _self.value, macarte);
                }
            }, 50);
        });
    }

}

function callbackMapAgendaOverlay(macarte) {
    console.log(macarte);
}

/* FILTRE POUR ENLEVER LES ELEMENTS PAR DEFAUT SUR LA GOOGLE MAPS SUR LES PAGES DE LISTING */
function filterMapListing(options) {
    return options;
}


/* FILTRE POUR ENLEVER LES ELEMENTS PAR DEFAUT SUR LA GOOGLE MAPS SUR LA PAGE DE DETAIL SIT ET AGENDA */
function filterMapDetail(options) {
    return options;
}


/* FILTRE POUR LA MAP SUR LA PAGE CONTACT */
function filterMapContact(options) {
    return options;
}


// Map - Overlay Map Default
function callbackMapOverlay(macarte) {

    macarte.zoomControl.setPosition('topleft');

    /* A l'ouverture de l'overlay, on recharge la carte */
    th_overlay.addCallbackOpen(function (overlayId) {
        if (overlayId == 'overlay-map') {

            callbackMapListing(macarte);

            setTimeout(function () {
                listingSit.macarte.invalidateSize();
                listingSit.macarte.fitBounds(listingSit._map.markerBounds.getBounds());
            }, 250);
        }
    })
}


function initmaps() {
    th_maps.init(thConfig.map.init);
}


// Init Map
// th_maps.init(thConfig.map.init);

if (typeof Gp != 'undefined') {
    Gp.Services.getConfig({
        apiKey: "essentiels",
        onSuccess: initmaps
    });
} else {
    th_maps.init(thConfig.map.init);
}


// document.addEventListener('scroll', init_thMaps);

$('.group').click(function () {
    $(this).toggleClass('opened');
});

$('#btn-menu-map').click(function () {
    $('#carte-menu').toggleClass('show-menu-map');
    $('.menu-carte-mobile').toggleClass('btn-map-active');
});


function parseMapPopup(macarte) {

    console.log('create poppup');

    var markers = [];

    $(macarte._container).parent().find('.maps-popup').each(function () {

        var latlng = {lat: this.getAttribute('data-lat') * 1, lng: this.getAttribute('data-lng') * 1};
        var marker = th_maps.createMarker(macarte, latlng, this.getAttribute('data-markericon'));
        th_maps.createInfoWindow(this.innerHTML, marker, 275);
        markers.push(marker);
    });

    var group = new L.featureGroup(markers);
    macarte.fitBounds(group.getBounds());

    if (macarte.getZoom() > 11) {
        macarte.setZoom(11);
    }

    console.log('poppup done');

    return markers;
}

function addMapPopup(macarte) {

    var latlng = {lat: $(macarte._container).attr('data-lat') * 1, lng: $(macarte._container).attr('data-lng') * 1};
    var marker = th_maps.createMarker(macarte, latlng, 'marker');

    if (macarte.getZoom() > 11) {
        macarte.setZoom(11);
    }
}