/*
 Script modifié au niveau du updateDispoCalendar pour ajouter des aria-labels dynamiques dans le cadre de la mise en conformité RGAA du site
 */

var dispo = {

    dispo: '',

    init:function(){

        var $selectMonth = $('#sit-dispo-month');
        var $selectYear = $('#sit-dispo-year');

        if($selectMonth.length > 0 && $selectYear.length > 0){
            var curMonth = $selectMonth.val();
            var curYear = $selectYear.val();
            dispo.dispo = $selectYear.find('option:selected').data('dispo');

            dispo.updateDispoCalendar(curMonth,curYear);

            $selectMonth.on('change',function(){
                curMonth = $selectMonth.val();
                dispo.updateDispoCalendar(curMonth,curYear);
            });
            $selectYear.on('change',function(){
                curYear = $selectYear.val();
                dispo.dispo = $selectYear.find('option:selected').data('dispo');
                dispo.updateDispoCalendar(curMonth,curYear);
            });

        }

    },

    updateDispoCalendar: function(month,year){
        var tableTr = dispo.generateDom(month,year);

        var sitDispoEl = document.getElementById('sit-dispo');
        sitDispoEl.innerHTML = '';
        $.each(tableTr,function(k,trTd){
            var tr = document.createElement('tr');
            $.each(trTd,function(k,td){
                tr.appendChild(td);
            });
            sitDispoEl.appendChild(tr);
        });


        // On reprend la valeur textuel (disponible, à vérifier auprès de l'hébergeur, complet / fermé) inseré en single-apidae.php, comme ça on peut avoir la traduction des chaines.


        var disponibiliteAVerifier = $('.legend-verif').text();
        var disponible = 'Chambre ' + $('.legend-dispo').text();
        var complet = 'Hébérgement ' + $('.legend-complet').text();
        var moisDisabled = $('.legend-mois-disabled').text();


        // Pour chaque cellule du tableau on récupère la date courante
        $('#sit-dispo td').each(function () {
            var getAttributeDate = $(this).attr('data-date');

            // Selon la disponibilité assigné au jour on change le contenu de la variable getAttributeDate
            var getAttributeAria = '';

            if($(this).hasClass('day-verif')) {
                getAttributeAria = disponibiliteAVerifier;
            } else if($(this).hasClass('day-dispo')) {
                getAttributeAria = disponible;
            } else if($(this).hasClass('day-complet')) {
                getAttributeAria = complet;
            } else {
                getAttributeAria = moisDisabled;
            }

            // On fait une concatenation entre la date de la cellule et la string qu'on a récupéré au début, qui nous permet de savoir le status de la disponibilité dans un jour spécifique.

            var fullAriaLabel = getAttributeDate + ' ' + getAttributeAria;

            // On ajoute à la cellule un attribut aria-label égal à la valeur de la nouvelle variable fullAriaLabel, comme ça le lecteur d'écran peut annoncer la date du jour suivi par son status
            // de disponibilité
            $(this).attr('aria-label', fullAriaLabel);

        });

    },

    leapYear: function(year){
        return new Date(year, 1, 29).getDate() === 29;
    },

    generateDom: function(month,year){
        var firstDay;

        if (!dispo.leapYear(year)) {
            firstDay = new Date(year+'-'+month+'-01');
        } else {
            firstDay = new Date(year+'-'+month+'-02');
        }
        var currentDay = firstDay;
        var dayBefore = 0;
        if(firstDay.getDay() > 1){
            dayBefore = firstDay.getDay()-1;
        }
        var calendarIsFinish = false;
        var finish = false;
        var cpt = 0;
        var number;
        var lastDay = 0;

        var table = [[]];

        while(dayBefore != 0){
            currentDay = new Date(firstDay.getTime()-((86400*dayBefore)*1000));
            number = currentDay.getDate();
            dayBefore--;
            table[table.length-1].push(dispo.generateTD(currentDay,true));
            cpt++;
        }

        // on ajoute le jour 1
        table[table.length-1].push(dispo.generateTD(firstDay,false));
        number = 1;
        cpt++;
        currentDay = firstDay;


        while(finish == false){
            cpt++;

            currentDay = new Date(currentDay.getTime()+86400000);
            number = currentDay.getDate();

            if(number == 1){
                calendarIsFinish = true;
            }

            if(cpt == 8 && calendarIsFinish != true){
                cpt = 1;
                table.push([]);
            }

            if(cpt == 8 && calendarIsFinish){
                finish = true;
            }else{
                table[table.length-1].push(dispo.generateTD(currentDay,calendarIsFinish));
            }


            // console.log('---');
            // console.log(number);
            // console.log(cpt);
            // console.log(calendarIsFinish);
            // console.log(finish);

        }

        return table;
    },

    generateTD: function(date,disabled){
        var dayOfYear = dispo.dateToDayOfYear(date);
        var td = document.createElement('td');

        td.innerHTML = date.getDate();
        td.setAttribute('data-date',date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear());
        td.setAttribute('data-dayofyear',dispo.dateToDayOfYear(date));
        td.className += ' '+dispo.dayToDispo(dayOfYear, disabled);

        return td;
    },

    dateToDayOfYear: function(date){
        var start = new Date(date.getFullYear(), 0, 0);
        var diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
        var day = Math.floor(diff / 86400000);
        return day;
    },

    dayToDispo: function(day, disabled){
        if(dispo.dispo.length > day-1){
            if(dispo.dispo[(day-1)] == 'S') {
                return 'day-disabled';
            }
            if(dispo.dispo[(day-1)] == 'D') {
                return 'day-dispo';
            }
            if(dispo.dispo[(day-1)] == 'C'){
                return 'day-complet';
            }
            if(dispo.dispo[(day-1)] == 'F'){
                return 'day-complet';
            }
        }

        if (disabled) {
            return "day-disabled";
        } else {
            return 'day-verif';
        }
    }

};

if($('.btn-dispo').length > 0) {
    dispo.init();
}