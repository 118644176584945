var listingSit = {

    lastRequestId: null,
    macarte: null,
    moncallbackCarte: null,
    lastMarkerGroupe: null,

    pickerDu: null,
    pickerAu: null,

    _map: {
        map: null,
        isMobile: false,
        markers: {},
        popins: {},
        markerIcon: 'marker',
        markerWidth: 300,

        timeoutUpdate: null,
    },

    _selector: {
        form: '#facette',
        scrollable: 'html,body',
        autocomplete: '.autocomplete select',
        outoverlay: '#outoverlay',
        nb_results: '.resultats',
        nb_filters: '.filtres-actifs',
        cardsContainer: ".wrapper-listing",
        paginationContainer: ".bloc-pagination"
    },

    callbacks: {
        afterListingUpdate: null
    },

    init: function () {
        listingSit.popEvent();
        listingSit.formEvent();
        listingSit.paginationEvent();
        listingSit.checkboxEvent();
        listingSit.initMasonry();
        listingSit.form.outOfOverlayinit();
    },

    checkboxEvent: function () {

        $('input[type="checkbox"]:not(.parsed):not(.ignore-change),input[type="radio"]:not(.parsed):not(.ignore-change),input[type="hidden"]:not(.parsed),select:not(.parsed)', listingSit._selector.form).on('change', function () {
            $(this).parents('form').submit();
        }).addClass('parsed');

        $('.wrapper-date .frm_date:not(.parsed)', listingSit._selector.form).each(function () {
            var picker = new Pikaday({field: this, format: 'DD/MM/YY', placeholder: '__/__/__'});

            if ($(this).attr('name') == 'dr_du') {
                listingSit.pickerDu = picker;
                listingSit.pickerDu.setMinDate(new Date());
            }

            if ($(this).attr('name') == 'dr_au') {
                listingSit.pickerAu = picker;
                listingSit.pickerAu.setMinDate(new Date());

                if ($(this).hasClass("restrict-hot")) {
                    console.log('restrict');

                    listingSit.pickerAu.setMaxDate(new Date().setDate(new Date().getDate() + 14));
                }
            }

            $(this).addClass('parsed').on('change', function () {
                $('input[name="dr_spe"]').first().prop('checked', false);
                $(listingSit._selector.form).submit();

                var dateDu = listingSit.pickerDu.getDate();
                var dateAu = listingSit.pickerAu.getDate();

                if (dateDu) {
                    listingSit.pickerAu.setMinDate(dateDu);

                    if (dateAu && dateDu.getTime() > dateAu.getTime()) {
                        listingSit.pickerAu.setDate(dateDu.addDays(1));
                    }
                }

                if ($(this).attr('name') == 'dr_du' && dateDu) {
                    listingSit.pickerAu.setDate(listingSit.pickerDu.getDate().addDays(1));
                }
            });
        });

        $('[name=dr_spe]', listingSit._selector.form).on("click", function () {
            if (this.checked && !this.classList.contains("is-active")) {
                var data_date = $(this).data();
                listingSit.pickerDu.setDate(new Date(data_date.start));
                listingSit.pickerAu.setDate(new Date(data_date.end));
                this.classList.add("is-active");
                $(listingSit._selector.form).submit();

            } else {
                this.checked = false;
                this.classList.remove("is-active");
            }
        });
    },

    scrollToTop: function () {
        $(window).scrollTop(0);

        if (document.querySelector(".page-listing-agenda")) {
            $(listingSit._selector.cardsContainer).html('<p class="aligncenter">Chargement en cours ...</p>');
        } else {
            $('.wrapper-listing .bloc-listing').height($('.wrapper-listing .bloc-listing').height()).html('<p class="aligncenter">Chargement en cours ...</p>');
        }
    },

    popEvent: function () {
        window.addEventListener('popstate', function (event) {
            listingSit.scrollToTop();

            thisRequestid = new Date().getTime();
            listingSit.lastRequestId = thisRequestid;

            $.get(document.location.href, function (data) {
                if (listingSit.lastRequestId == thisRequestid) {
                    listingSit.updateContent(data);
                }
            });
        });
    },

    formEvent: function () {
        $(listingSit._selector.form).submit(function (e) {
            e.preventDefault();
            e.stopPropagation();

            listingSit.scrollToTop();
            var query = $(":input", this)
                .filter(function (index, element) {
                    return $(element).val() != '';
                })
                .filter(function (index, element) {
                    return $(element).val() != 'empty';
                })
                .serialize();

            var url = this.getAttribute('action');

            var actionUrl = url + '?' + query;
            if (typeof window.history != "undefined" && window.history.pushState) {
                window.history.pushState("", "", actionUrl);

            } else {
                document.location.href = actionUrl;
            }

            thisRequestid = new Date().getTime();
            listingSit.lastRequestId = thisRequestid;

            $.get($(this).attr('action'), $(this).serialize(), function (data) {
                if (listingSit.lastRequestId == thisRequestid) {
                    listingSit.updateContent(data);
                }
            });
        });
    },

    paginationEvent: function () {
        $('.bloc-pagination:not(.not-ajax) a').on('click', function (e) {
            e.preventDefault();

            listingSit.scrollToTop();

            var actionUrl = $(this).attr('href');
            if (typeof window.history != "undefined" && window.history.pushState) {
                window.history.pushState("", "", actionUrl);
            } else {
                document.location.href = actionUrl;
            }

            thisRequestid = new Date().getTime();
            listingSit.lastRequestId = thisRequestid;

            $.get(actionUrl, {}, function (data) {
                if (listingSit.lastRequestId == thisRequestid) {
                    listingSit.updateContent(data);
                }
            });
        });
    },

    updateContent: function (data) {

        var cpt = 0;
        $('.wrapper-tri .tri').each(function () {
            cpt++;
            if (!$(this).hasClass('open')) {
                $(this).html('').removeClass('parsed').append($('.wrapper-tri .tri:nth-child(' + cpt + ') > *', data));
                th_dropdown.parse($('.wrapper-tri'));
            }
        });

        listingSit.destroyMasonry();
        $(listingSit._selector.cardsContainer).html('').append($(listingSit._selector.cardsContainer + ' > *', data));

        $('.sidebar-listing span.results').html($('.sidebar-listing span.results', data).html());
        $(listingSit._selector.nb_results).html($(listingSit._selector.nb_results, data).html());

        // $(listingSit._selector.paginationContainer).html('').append($(listingSit._selector.paginationContainer + ' > *', data));
        $(listingSit._selector.paginationContainer).html($(listingSit._selector.paginationContainer, data).html());
        listingSit.initMasonry();
        listingSit.paginationEvent();

        init_ajaxmail_nonpartenaire();

        egalizeAll();

        var lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });

        listingSit.updateCarteContent(listingSit.macarte);


        $('.wrapper-listing .bloc-listing').height('auto');

        if (stickySidebar) {

            if (document.body.clientHeight < $(listingSit._selector.cardsContainer).height()) {
                $('.maps').height('100vh');
            } else {
                $('.maps').height('100%');
            }

            stickySidebar.updateSticky();
        }

        if (listingSit.callbacks.afterListingUpdate) {
            listingSit.callbacks.afterListingUpdate(data);
        }
    },

    updateCarteContent: function (macarte, moncallbackCarte) {
        if (listingSit.lastMarkerGroupe) {
            th_maps.hideGroupMarker(listingSit.lastMarkerGroupe);
        }

        listingSit.macarte = macarte;
        listingSit.moncallbackCarte = moncallbackCarte;
        listingSit.lastMarkerGroupe = 'listing-' + (new Date().getTime());

        var listAllMarkers = [];

        // marker = th_maps.createMarker(macarte,{lat:49.6988645,lng:4.9477663},'green');
        $('.wrapper-listing .bloc-listing > div:not(.grid-sizer):not(.grid-gutter)').each(function () {
            var marker;
            if ($(this).attr('data-lat') != '' && $(this).attr('data-lng') != '') {
                var geo = {
                    lat: $(this).attr('data-lat').replace(',', '.') * 1,
                    lng: $(this).attr('data-lng').replace(',', '.') * 1
                };
                marker = th_maps.createMarker(macarte, geo, 'marker', listingSit.lastMarkerGroupe);
                listAllMarkers.push(marker);

                // marker = th_maps.createMarker(macarte,{lat:49.6988645,lng:4.9477663},'green');
                /*th_maps.createInfoWindow('<a target="_blank" href="'+$('a',this).attr('href')+'" id="map-inte-container">' +
                 '<div class="map-inte-content">' +
                 '<div class="map-inte-header"><figure><img src=' + $('img', this).attr('src') + ' width="260" height="160" alt=' + $('img', this).attr('alt') + ' class="fit-cover" /></figure></div>' +
                 '<div class="map-inte-content-text"><div class="classement"><div><span class="icon-star"></span><span class="icon-star"></span><span class="icon-star"></span><span class="icon-star"></span> <span class="icon-star disabled"></span></div></div><h2>' + $('h2', this).text() + '</h2>' +
                 '<p class="location">' + $('.location', this).text() + '</p>' +
                 '</div></div></a>', marker, 260);
                 */
                var rates = $('.rate', this).html();
                if (!rates) {
                    rates = '';
                } else {
                    rates = '<div class="rate">' + rates + '</div>';
                }
                var theme = $('.theme', this).html();
                if (!theme) {
                    theme = '';
                } else {
                    theme = '<span class="theme">' + theme + '</span>';
                }

                var isEvent = $(this).data("isevent");
                var partenaire = $(this).data('partenaire');

                if (isEvent) {
                    th_maps.createInfoWindow('<a target="_blank" href="' + $('a', this).attr('href') + '" id="map-inte-container">' +
                        '<div class="map-inte-content">' +
                        '<div class="map-inte-header">' +
                        //'<div class="wrapper-price"><div class="price"><span>à partir de <span class="prix">79<span>€</span></span></span></div><div class="reserve"><span>réservez en ligne</span></div> </div>' +
                        '<figure class="fit-cover"><img src=' + $('img', this).attr('data-src') + ' width="260" height="160" alt=' + $('img', this).attr('alt') + ' class="fit-cover"' +
                        ' /></figure>' +
                        '</div>' +
                        '<div class="map-inte-content-text">' +
                        '<div class="wrapper-rate">' + rates + '<span class="location">' + $('.location', this).text() + '</span></div>' + theme + '<h3>' + $('h2', this).text() + '</h3>' +
                        '<span class="link">' + $('.link', this).text() + '</span>' +
                        '</div></div></a>', marker, 260);
                } else if (partenaire) {
                    th_maps.createInfoWindow('<a target="_blank" href="' + $('a', this).attr('href') + '" id="map-inte-container">' +
                        '<div class="map-inte-content">' +
                        '<div class="map-inte-header">' +
                        //'<div class="wrapper-price"><div class="price"><span>à partir de <span class="prix">79<span>€</span></span></span></div><div class="reserve"><span>réservez en ligne</span></div> </div>' +
                        '<figure class="fit-cover"><img src=' + $('img', this).attr('data-src') + ' width="260" height="160" alt=' + $('img', this).attr('alt') + ' class="fit-cover"' +
                        ' /></figure>' +
                        '</div>' +
                        '<div class="map-inte-content-text">' +
                        '<div class="wrapper-rate">' + rates + '<span class="location">' + $('.location', this).text() + '</span></div>' + theme + '<h3>' + $('h2', this).text() + '</h3>' +
                        '<span class="link">' + $('.link', this).text() + '</span>' +
                        '</div></div></a>', marker, 260);
                } else {
                    th_maps.createInfoWindow('<div id="map-inte-container" class="card-non-partenaire">' +
                        '<div class="map-inte-content">' +
                        $('.card-non-partenaire', this).html() +
                        '</div>' +
                        '</div>', marker, 260);

                    marker.on('click', function () {
                        init_ajaxmail_nonpartenaire();
                    })
                }

                var bounds = new L.featureGroup(listAllMarkers);

                // bounds.extend(marker.position);
                listingSit.macarte.fitBounds(bounds.getBounds());
                defaultZoom = listingSit.macarte.getZoom();

                $(this).on("mouseover", function () {
                    marker.setIcon(th_maps.markersIcons['marker-hover']);
                    marker.openPopup();
                });

                $(this).on("mouseleave", function () {
                    marker.setIcon(th_maps.markersIcons['marker']);
                    marker.closePopup();
                });
            }


        });


        if ($('input[name="lat"]').val() == '') {
            var bounds = new L.featureGroup(listAllMarkers);
            listingSit._map.markerBounds = bounds;
            listingSit.macarte.fitBounds(bounds.getBounds());
        }

        if (listingSit.moncallbackCarte) {
            listingSit.moncallbackCarte(macarte, bounds);
        }


    },

    initMasonry: function () {
        if ($('.grid-masonry').length) {

            var $grid = $('.grid-masonry').imagesLoaded(function () {
                // init Masonry after all images have loaded
                $grid.masonry({
                    // set itemSelector so .grid-sizer is not used in layout
                    itemSelector: '.grid-item',
                    // use element for option
                    columnWidth: '.grid-sizer',
                    gutter: '.grid-gutter'
                });

                if (stickySidebar) {
                    if (document.body.clientHeight < $(listingSit._selector.cardsContainer).height()) {
                        $('.maps').height('100vh');
                    } else {
                        $('.maps').height('100%');
                    }
                    stickySidebar.updateSticky();
                }
            });
            // $('.grid-masonry').masonry({
            //     // set itemSelector so .grid-sizer is not used in layout
            //     itemSelector: '.grid-item',
            //     // use element for option
            //     columnWidth: '.grid-sizer'
            // });
        }
    },

    destroyMasonry: function () {
        if ($('.grid-masonry').length) {
            $('.grid-masonry').masonry('destroy');
        }
    },

    form: {
        /**
         * Mets en place les listener pour les facettes en dehors de l'overlay
         * - actualise l'équivalent dans l'overlay avec la bonne valeur
         * - mets à jours la liste de valeurs de facettes sélectionnées
         */
        outOfOverlayinit: function () {
            if ($('.form-reserve .frm_date:not(.parsed)').length > 0) {
                var isCalendar = document.querySelector(".page-listing-agenda");
                var shouldInitDates = true;

                // Sur mobile en vue calendrier, il faut ouvrir l'overlay filtres au click sur la barre de filtres
                if (isCalendar && isMobileOrSmaller()) {
                    shouldInitDates = false;

                    $(".form-reserve").on("click", function() {
                        th_overlay.open("overlay-filtres");
                    });
                }

                if (shouldInitDates) {
                    var firstFieldDate = $('.form-reserve .frm_date').first()[0];
                    var lastFieldDate = $('.form-reserve .frm_date').last()[0];
                    var firstPikaday = new Pikaday({field: firstFieldDate, format: 'DD/MM/YY', firstDay: 1});
                    var lastPikaday = new Pikaday({field: lastFieldDate, format: 'DD/MM/YY', firstDay: 1});
                    if (firstPikaday.getDate()) {
                        lastPikaday.setMinDate(firstPikaday.getDate());
                    }
                    if ($(firstFieldDate).hasClass("restrict-hot")) {
                        firstPikaday.setMinDate(new Date());
                        firstPikaday.setMaxDate(new Date(new Date().setDate(new Date().getDate() + 14)));
                    }
                    if ($(lastFieldDate).hasClass("restrict-hot")) {
                        lastPikaday.setMinDate(new Date());
                        lastPikaday.setMaxDate(new Date(new Date().setDate(new Date().getDate() + 14)));
                    }

                    $('.form-reserve .frm_date').first().on('change', function () {
                        input_name = $(this).attr('name')
                        input_val = $(this).val();
                        $('[name="' + input_name + '"]', listingSit._selector.form).val(input_val);

                        lastPikaday.setMinDate(firstPikaday.getDate());
                        if (!lastPikaday.getDate() || firstPikaday.getDate().getTime() > lastPikaday.getDate().getTime()) {
                            lastPikaday.setDate(new Date(firstPikaday.getDate().getTime() + 1000 * 60 * 60 * 24));
                        } else {
                            $(listingSit._selector.form).submit();
                        }
                    });
                    $('.form-reserve .frm_date').last().on('change', function () {
                        input_name = $(this).attr('name')
                        input_val = $(this).val();
                        $('[name="' + input_name + '"]', listingSit._selector.form).val(input_val);

                        $(listingSit._selector.form).submit();
                    });

                    $('.form-reserve .reset-reserv').on('click', function () {
                        firstPikaday.setDate(null);
                        lastPikaday.setDate(null);
                    });

                    // les select en dehors de l'overlay
                    $('select', listingSit._selector.outoverlay).on('change', function () {
                        input_name = $(this).attr('name')
                        input_val = $(this).val();
                        $('[name="' + input_name + '"]', listingSit._selector.form).val(input_val);
                        $(listingSit._selector.form).submit();
                    })


                    $(firstFieldDate).addClass('parsed');
                    $(lastFieldDate).addClass('parsed');
                }
            }

            if ($(".agenda-inputs input").length > 0) {
                $("input", ".agenda-inputs").on("change", function () {
                    var correspondingId = this.getAttribute("id").split("_outofoverlay")[0];

                    if (correspondingId) {
                        var correspondingInput = $(".group-date input[type='radio'][id='" + correspondingId + "']");

                        if (correspondingInput) {
                            correspondingInput.click();

                            correspondingInput.trigger("click");
                        }
                    }
                });
            }

            $('.wrapper-date .frm_date', listingSit._selector.form).on("change", function () {
                var id = this.getAttribute("id");
                var correspondingDate = $("[id='" + id + "_outofoverlay']", ".form-reserve");

                if (correspondingDate) {
                    $(correspondingDate).val(this.value);
                }
            })
        },
    }
};

var find_listing_sit = document.querySelector('.page-listing');
var find_listing_sit_agenda = document.querySelector(".page-listing-agenda");
if (find_listing_sit_agenda) {
    listingSit._selector.cardsContainer = ".inner-container-listing";

    listingSit.init();
} else if (find_listing_sit) {
    listingSit.init();
}

Date.prototype.addDays = function (days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}

function init_ajaxmail_nonpartenaire() {
    $('[data-js="mailsit"]').on('click', function (eventObject) {

        if (!$(this).hasClass('loaded')) {

            eventObject.preventDefault();
            var _this = $(this);

            $('.tooltiptext', _this).text(_this.data('loadingtext'));

            $.ajax({
                url: ajaxurl,
                method: 'POST',
                data: {
                    action: 'th_ajaxcall',
                    class: 'Apidae',
                    method: 'ajaxGetMail',
                    id_apidae: $(this).attr('data-apidaeid')
                },
                success: function (data) {
                    result = JSON.parse(data);
                    if (result.success) {
                        _this.attr('href', 'mailto:' + result.data[0]);
                        $('.tooltiptext', _this).text(result.data[0]);
                        _this.addClass('loaded');
                    } else {
                        console.log(result.data);
                    }
                },
            });
        }

    })
}

init_ajaxmail_nonpartenaire();



