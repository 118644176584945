setTimeout(function(){
    // init de algolia
    thuriaSearch.init(
        thConfig.algolia.id,
        thConfig.algolia.key,
        ['wp_posts_apidae'], // index(s) pour container apidae
        ['wp_posts_page','wp_posts_magazine'], // index(s) pour container un peu de lecture
        ['wp_posts_post'], // index(s) pour container pour les pros
        $('#inputfastsearch').get(0), // input de saisie
        $('#fast-search-container .wrapper').get(0), // résultats apidae
        $('.wrapper-search-algolia').get(0), // scroller pour chargement page suivante
        $('#fast-search-container-cms .owl-carousel').get(0), // résultats page + magazine
        $('#fast-search-container-pages-pro .owl-carousel').get(0) // résultats post
    );
},1000);