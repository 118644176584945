var th_rgaa_focus = {
    firstEl: null,
    lastEl: null,
    el: null,
    hasEvent: null,

    init: function(el, firstEl, lastEl) {
        th_rgaa_focus.el = el;
        th_rgaa_focus.firstEl = firstEl;
        th_rgaa_focus.lastEl = lastEl;

        if (!firstEl && !lastEl) {
            th_rgaa_focus.getFirstAndLastEl();
        }

        if (th_rgaa_focus.firstEl && th_rgaa_focus.lastEl) {
            th_rgaa_focus.focusTrap();
        }
    },

    getFirstAndLastEl: function() {
        var focusableEls = $("a[href], button, select, [tabindex]:not(span)", th_rgaa_focus.el);

        th_rgaa_focus.firstEl = focusableEls.first()[0];
        th_rgaa_focus.lastEl = focusableEls.last()[0];
    },

    focusTrap: function() {
        if (th_rgaa_focus.hasEvent) {
            th_rgaa_focus.el[0].removeEventListener("keydown", th_rgaa_focus.trapEvent);
        }

        th_rgaa_focus.el[0].addEventListener("keydown", th_rgaa_focus.trapEvent);

        th_rgaa_focus.hasEvent = true;
    },

    trapEvent: function(evt) {

        if (evt.key === 'Tab' || evt.keyCode === 9) {
            if (evt.shiftKey) /* shift + tab */ {
                if (document.activeElement === th_rgaa_focus.firstEl) {
                    th_rgaa_focus.lastEl.focus();
                    evt.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === th_rgaa_focus.lastEl) {
                    th_rgaa_focus.firstEl.focus();
                    evt.preventDefault();
                }
            }
        }
    }
};