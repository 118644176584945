$('.bloc-pub').each(function(){

    var $img = $('img',this);

    var intervalUpdatePub = false;
    $(window).on('resize',function(){
        clearTimeout(intervalUpdatePub);
        intervalUpdatePub = setTimeout(function(){updatePubImg($img);},500);
    });

    $(window).on('orientationchange',function(){
        clearTimeout(intervalUpdatePub);
        intervalUpdatePub = setTimeout(function(){updatePubImg($img);},500);
    });

    updatePubImg($img);
});

function updatePubImg($img){
    if(document.body.clientWidth < 768){
        $img.attr('src',$img.attr('data-src-md'));
    }else{
        $img.attr('src',$img.attr('data-src'));
    }
}
