var Ww = $(window).width();
var sidebar = document.querySelector('.page-detail');
var stickySidebar = false;

listingSIT = document.querySelector('.page-listing');

if(Ww > 980 && listingSIT){
    if(document.body.clientHeight < $('.wrapper-listing').height()){
        $('.maps').height('100vh - 84px');
    }else{
        $('.maps').height('100%');
    }
    stickySidebar = new StickySidebar('.fixed-map', {
        containerSelector: '.listing-map',
        innerWrapperSelector: '.maps',
        topSpacing: 84,
        bottomSpacing:0
    });

    stickySidebar.updateSticky();
}

$(document).ready(function(){
    if(stickySidebar){
        stickySidebar.updateSticky();
    }
});


$(window).on('orientationchange',updateStickyOnResize);
$(window).on('resize',updateStickyOnResize);


function updateStickyOnResize(){
    var Ww = $(window).width();
    if(Ww > 980 && listingSIT) {


        if(document.body.clientHeight < $('.wrapper-listing').height()){
            $('.maps').height('100vh');
        }else{
            $('.maps').height('100%');
        }

        if(stickySidebar){
            stickySidebar.update();
        }else{
            stickySidebar = new StickySidebar('.listing-map', {
                containerSelector: '.container-listing',
                innerWrapperSelector: '.maps',
                topSpacing: 0,
                bottomSpacing:0
            });
        }

    }
    if(Ww <=980 && stickySidebar){
        stickySidebar.destroy();
        stickySidebar = false;
    }
}

if($('.aside-sticky').length > 0) {
    initScrollStickyApidae = function(){
        var $aside = $('.aside-sticky');
        var $parent = $aside.parent();
        var asideDefaultTop = $aside.offset().top;
        var asideHeight = $aside.outerHeight();
        var asideParentOffetTop = $parent.offset().top;
        var asideParentBottom = asideParentOffetTop+$parent.outerHeight();
        var asideTopAtEnd = asideParentBottom-asideHeight-asideParentOffetTop-20;

        var timeoutEndScroll = null;
        $(document).on('scroll',function(){
            if(document.body.clientWidth < 980){
                $aside.attr('style','').removeClass('not-top');
                return true;
            }
            clearTimeout(timeoutEndScroll);
            timeoutEndScroll = setTimeout(function(){
                var st = $(document).scrollTop();
                asideHeight = $aside.outerHeight();
                asideTopAtEnd = asideParentBottom-asideHeight-asideParentOffetTop-20;
                if(st > asideParentOffetTop){
                    if(st < asideParentBottom-asideHeight-200+80){
                        $aside.css('top',st-asideParentOffetTop+20).addClass('not-top').addClass('is-sticky').removeClass('is-bottom');
                    }else{
                        $aside.css('top',(asideTopAtEnd+200)+'px').addClass('not-top').addClass('is-bottom').removeClass('is-sticky');
                    }
                }else{
                    $aside.attr('style','').removeClass('not-top').removeClass('is-bottom').removeClass('is-sticky')
                }

            },250);
        });

    };

    if(document.body.clientWidth >= 980){
        initScrollStickyApidae();
    }
}