th_overlay.addCallbackOpen(function(overlayId){
    $mapsNotLoaded = $('.maps.no-preload','#'+overlayId);
    if($mapsNotLoaded.length > 0){
        $mapsNotLoaded.each(function(){
            var map = th_maps.initSingleMap(this);
            $(this).removeClass('no-preload');
            setTimeout(function () {
                map.invalidateSize();
            }, 100);
            setTimeout(function () {
                map.invalidateSize();
            }, 250);
        });
    }

    if (!document.body.classList.contains("no-scroll")) {
        document.body.classList.add("no-scroll")
    }
});


th_overlay.addCallbackClose(function(overlayId) {
    if (document.body.classList.contains("no-scroll")) {
        document.body.classList.remove("no-scroll")
    }
});


if(isMobileOrSmaller()){
    var lastStOverlayScroll = 0;

    th_overlay.addCallbackOpen(function(overlayId){
        lastStOverlayScroll = $('body').scrollTop();
        $('body').css({'top':-lastStOverlayScroll+'px'}).addClass('fixed');
    });

    th_overlay.addCallbackClose(function(overlayId){
        $('body').removeClass('fixed').css({'top':0}).scrollTop(lastStOverlayScroll);
    });
}


