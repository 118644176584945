

// Move HTML content on Tablet Portrait
if ($(window).width() < 992) {
    $('.left-sidebar').append($('.menu-left > ul'));
}

var menuEmplacement = 0;
var timeoutResizeMenuEmplacement = null;

$(window).resize(function() {
    clearTimeout(timeoutResizeMenuEmplacement);
    timeoutResizeMenuEmplacement = setTimeout(moveMenuOnResize,500);
});

function moveMenuOnResize(){
    var wW = $(window).width();
    if (wW < 992 && menuEmplacement == 0) {
        menuEmplacement = 1;
        $('.left-sidebar').append($('.menu-left > ul'));
    }
    if (wW >= 992 && menuEmplacement == 1) {
        menuEmplacement = 0;
        $('.menu-left').prepend($('.menu-left > ul'));
    }
}


// Move HTML content on Mobile
if ($(window).width() < 600) {
    $('.title-bar').append($('.wrapper-lang'));
    $('.sub-share').prepend($('.social-tw'));
    $('.sub-share').prepend($('.social-fb'));
}

var menuEmplacementMobile = 0;
var timeoutResizeMenuEmplacementMobile = null;

$(window).resize(function() {
    clearTimeout(timeoutResizeMenuEmplacementMobile);
    timeoutResizeMenuEmplacementMobile = setTimeout(moveMenuOnResizeMobile,500);
});

function moveMenuOnResizeMobile(){
    var wW = $(window).width();
    if (wW < 600 && menuEmplacementMobile == 0) {
        menuEmplacementMobile = 1;
        $('.title-bar').append($('.wrapper-lang'));
        $('.sub-share').prepend($('.social-tw'));
        $('.sub-share').prepend($('.social-fb'));
    }
    if (wW >= 600 && menuEmplacementMobile == 1) {
        menuEmplacementMobile = 0;
        $('.menu-left').prepend($('.wrapper-lang'));
        $('.social-share > ul').prepend($('.social-tw'));
        $('.social-share > ul').prepend($('.social-fb'));
    }
}

// mes éléments
var labelMenuV2 = document.querySelector('label[for="menu-toggle"]');
    var inputOpenMenu = document.querySelector('#header-search');
var buttonCouvertureAccueil = document.querySelector('.algolia-search-opener');
var inputCheckboxToggleMenuV2 = document.getElementById('menu-toggle');
var inputRadioSearchMenuV2 = document.getElementById('menu-item-0');
var inputRadioMenuMenu = document.getElementById('menu-item-menu');
var inputFastSearch = document.getElementById('inputfastsearch');
var fastSearchContainer = document.getElementById('fast-search-container');
var fastSearchContainerCMS = document.getElementById('fast-search-container-cms');
var fastSearchContainerPagesPro = document.getElementById('fast-search-container-pages-pro');

var menuFocusBeforeOpen;
$(inputCheckboxToggleMenuV2).on('change',function(){
    setTimeout(function(){
        if(inputCheckboxToggleMenuV2.checked){
            document.body.style.overflow = 'hidden';
            $('#header-v2').addClass('menu-open');
            $("#widget-panier").appendTo("#panier-menu");
            menuFocusBeforeOpen = $('*:focus').get(0);
            console.log('ouvre menu');
        }
        else{
            document.body.style.overflow = 'auto';
            $("#widget-panier").appendTo("#panier-ecran");
            $('#header-v2').removeClass('menu-open');
            if(menuFocusBeforeOpen){
                $(menuFocusBeforeOpen).focus();
            }else{
                $(labelMenuV2).focus();
            }
            console.log('ferme menu');
        }
    },250);
});

$(inputFastSearch).parent().on('submit',function(e){
    e.preventDefault();
    e.stopPropagation();

    $(inputFastSearch).blur();
});

if(document.body.clientWidth < 740 && inputRadioMenuMenu){
    inputRadioMenuMenu.click();
}
// CSS spécifique pour tous les IE et autres si besoin avec la class compatibility-mode
if(navigator.userAgent.match(/trident/gi) || navigator.appName == 'Microsoft Internet Explorer'){
    if(document.getElementById('header-v2')){
        document.getElementById('header-v2').className += ' compatibility-mode';
    }
}

// CSS spécifique pour les tablettes / mobiles touch device (retrait css de certaines animations)
if(isTouchDevice()){
    document.body.className += ' touch-mode';
}

// Active l'animation par défaut du menu, doit être activé après ouverture car sinon, a l'ouverture de la page le site anime la fermeture du menu //addAnimationClassMenuV2
if(labelMenuV2){
    labelMenuV2.addEventListener('click keypress', addAnimationClassMenuV2);

    $('.cta-close-menu').on('click', function (e) {
        labelMenuV2.click();
    });
    $('label[for="menu-item-0"]').on('click', function () {
        setTimeout(function () {
            inputFastSearch.focus();
        }, 200);
    });
}

if(inputFastSearch){
    inputFastSearch.value = '';
}

if($('[name="menu-choices"]:checked').val() != 1 && $('[name="menu-choices"]:checked').val() != -1){
    $('[name="menu-choices"][value="1"]').click();
}

if(inputOpenMenu){
    inputOpenMenu.addEventListener('click',openSearchMenu);
}
if(buttonCouvertureAccueil){
    buttonCouvertureAccueil.addEventListener('click',openSearchMenu);
}

function addAnimationClassMenuV2(e) {
    // thuriaSearch.init('2U6M4ATI72','0c11a969ee1627d469a844722c8e196e','wp_prod_posts_tours','wp_prod_posts_page',inputFastSearch,fastSearchContainer,fastSearchContainerTours,fastSearchContainerPost);
    document.getElementById('nav-v2').className = 'add-animation';
    labelMenuV2.removeEventListener('click', addAnimationClassMenuV2);
    inputOpenMenu.removeEventListener('click', addAnimationClassMenuV2);
}


// Les tablettes supporte très mal le changement d'orientation avec les calc et les animations, on ferme automatiquement le menu quand une tablette change de sens
document.addEventListener('orientationchange',function(){
    if(inputCheckboxToggleMenuV2 && inputCheckboxToggleMenuV2.checked){
        labelMenuV2.click();
    }
});

if(inputFastSearch){
    // retour a l'onglet recherche au focus
    inputFastSearch.addEventListener('focus',function(){
        inputRadioSearchMenuV2.checked = true;
    });

    // au blur, si le input contient une donnée, on la laisse avec le input par dessus la phrase recherche instant
    inputFastSearch.addEventListener('blur',function(){
        if(inputFastSearch.value.length > 0){
            inputFastSearch.className = 'has-content';
        }else{
            inputFastSearch.className = '';
        }
    });
}

$('.right-submenu').each(function(){
    manageScrollMouseIcon(this);
});

function manageScrollMouseIcon(el){

    var state = 0;

    if(el.scrollHeight > el.getBoundingClientRect().height){
        el.className += ' show-scroll';
        state = 1;
    }

    $("a.can-scroll",el).on('click',function(e){
        e.preventDefault();
        e.stopPropagation();
        $(el).animate({scrollTop:el.getBoundingClientRect().height},400);
    });

    el.addEventListener('scroll',function(){
        if(el.scrollTop > 0 && state == 1){
            state = 0;
            el.className = el.className.replace(' show-scroll','');
        }else if( state == 0 && el.scrollTop == 0){
            state = 1;
            el.className += ' show-scroll';
        }
    });
}

function openSearchMenu(){
    if (inputRadioSearchMenuV2) {
        inputRadioSearchMenuV2.checked = true;
        labelMenuV2.click();
        setTimeout(function () {
            inputFastSearch.focus();
        }, 400);
    }
}


$('#header-v2 .content-wrapper nav a, #nav-v2 button:not(.cta-close-menu)').on('click',function(e){
   // e.preventDefault();
    var target = this.getAttribute('data-for');

    if(target){
        // ouvre le bon onglet
        document.getElementById(target).checked = true;

        // focus le premier element
        var screenId = target.split('-');
        screenId = screenId[screenId.length-1];

        setTimeout(function(){
            $('a,button:not(.acces-premier-niveau)', '#submenu-' + screenId).first().focus();
        },500);

    }else{
        // ouvre la recherche par défaut
        inputRadioSearchMenuV2.checked = true;
    }

    if(!inputCheckboxToggleMenuV2.checked){
        labelMenuV2.click();
    }
});


$('#header-v2 .right-submenu').each(function(){

    var rightMenuIndex = ($(this).attr('id')).replace('submenu-','');

    var _self = this;

    var preventBlurLastItem = false;
    var firstItemIsFocus = false;

    $('a,button:not(.cta-close-menu)',this).last().on('blur',function(e) {
        console.log(preventBlurLastItem);
        if(!preventBlurLastItem){
            e.preventDefault();
            e.stopPropagation();
            console.log(e);
            $('a,button',_self).first().focus();
        }
    });

    $('a,button:not(.cta-close-menu)',this).first().on('focus',function(e) {
        firstItemIsFocus = true;
    });
    $('a,button:not(.cta-close-menu)',this).first().on('blur',function(e) {
        firstItemIsFocus = false;
    });


    document.addEventListener('keydown',function(e){
        if(e.keyCode == 9 && e.shiftKey){
            preventBlurLastItem = true;
            if(firstItemIsFocus){
                e.preventDefault();
                e.stopPropagation();
                console.log(preventBlurLastItem);
                $('a,button',_self).last().focus();
                firstItemIsFocus = false;
            }
        }

    });
    document.addEventListener('keyup',function(e){
        if(e.keyCode == 9 || e.keyCode == 16){
            preventBlurLastItem = false;
            console.log(preventBlurLastItem);

        }
    });

});

$(window).on('keydown', function (e) {
    if (inputCheckboxToggleMenuV2.checked && e.keyCode == 27) {
        e.preventDefault();
        e.stopPropagation();
        inputCheckboxToggleMenuV2.checked = false;

        $("#header-v2").removeClass("menu-open");
    }
});

$('label[for="menu-toggle"]').on('keypress', function (e) {
    if (e.keyCode == 13 || e.keyCode == 32) {
        inputCheckboxToggleMenuV2.checked = true;
    }
});

$(document).ready(function () {
    $(".left-sidebar .menus-left ul:first-of-type li:nth-of-type(2)").find('button').attr('aria-expanded', 'true');
});

$("#header-v2 .wrapper-circle nav li").on('click', function () {

    var dataAttribute = $(this).find("a").attr("data-for");

    $(".left-sidebar .menus-left ul:first-of-type li").each(function () {
        var currentLink = $(this).find("button");
        if (currentLink.attr("data-for") === dataAttribute) {
            $(this).focus();
            $(this).addClass('active');
            currentLink.attr('aria-expanded', 'true');
        } else {
            $(this).removeClass('active');
            currentLink.attr('aria-expanded', 'false');
        }
    });

    console.log(dataAttribute);
});