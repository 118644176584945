// Instance de la classe blockReservationV2 qui gère la librairie pickaday.js
barreListingSit = new blockReservationV2('.barre-listing-sit .form-reserve:not(.barre-listing-agenda)');


$(document).ready(function () {

    var filtreToday = $("label[for=today_outofoverlay]");

    var filtreWe = $("label[for=ce_week_end_outofoverlay]");

    filtreToday.on('click', function () {
        $(this).next().removeClass('selected');
        $(this).addClass('selected');
    });

    filtreWe.on('click', function () {
        $(this).prev().removeClass('selected');
        $(this).addClass('selected');
    });


    $(document).mouseup(function (e) {
        if (!filtreToday.is(e.target) || !filtreToday.has(e.target).length) {
            filtreToday.removeClass("selected");
        }
    });

    $(document).mouseup(function (e) {
        if (!filtreWe.is(e.target) || !filtreWe.has(e.target).length) {
            filtreWe.removeClass("selected");
        }
    });

});