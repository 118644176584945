/**
 * Version RGAA :
 * - Focus sur le bouton de fermeture dans l'overlay
 * - Fermeture du dernier overlay ouvert via ECHAP a tout moment
 * - Remet le focus sur l'element qui a ouvert l'overlay
 */

var th_overlay = {

    selector_overlay: '.overlay',
    selector_overlay_shadow: '.shadow-overlay',

    callbackOpen: [],
    callbackClose: [],
    hashList: {},

    // RGAA
    isEscapeListening: false,
    lastOpenedId: [],
    focusElOnClose: {},

    init: function () {
        th_overlay.parseOverlayShadow($('body'));
        th_overlay.parse($('body'));
        th_overlay.openOnLoad();

        // RGAA on ecoute la touche echap pour fermer les overlay ouvert
        if(!th_overlay.isEscapeListening){
            th_overlay.isEscapeListening = true;
            document.addEventListener('keydown',function(e){
                if(th_overlay.lastOpenedId.length > 0 && e.key === "Escape") {
                    e.preventDefault();
                    e.stopPropagation();
                    // on ferme le dernier ouvert
                    th_overlay.close(th_overlay.lastOpenedId[th_overlay.lastOpenedId.length-1]);
                }
            });
        }
    },

    addCallbackOpen: function (callback) {
        th_overlay.callbackOpen.push(callback);
    },
    addCallbackClose: function (callback) {
        th_overlay.callbackClose.push(callback);
    },

    parseOverlayShadow: function () {
        $(th_overlay.selector_overlay_shadow).on('click', function (e) {
            e.preventDefault();
            th_overlay.close();
        });
    },

    parse: function (el) {

        $(th_overlay.selector_overlay).each(function () {

            var overlayId = this.id;
            var targetOpen = $('a[href="#' + overlayId + '"]:not(.parsed-overlay):not(.close), [data-overlay-open="' + overlayId + '"]:not(.parsed-overlay)');

            if ($(this).data("hash") !== undefined) {
                th_overlay.hashList[$(this).attr("data-hash")] = overlayId;
            }

            targetOpen.addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-open');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }


                    // RGAA : On stock l'element qui a ouvert l'overlay pour y revenir a sa fermeture
                    th_overlay.focusElOnClose[idOverlay] = this;


                    th_overlay.open(idOverlay);
                });

            $('a[href="#' + overlayId + '"].close:not(.parsed-overlay), [data-overlay-close="' + overlayId + '"]:not(.parsed-overlay)')
                .addClass('parsed-overlay')
                .on('click', function (e) {
                    e.preventDefault();
                    var idOverlay = $(this).attr('href');
                    if (!idOverlay) {
                        idOverlay = $(this).attr('data-overlay-close');
                    } else {
                        idOverlay = idOverlay.replace('#', '');
                    }

                    if (!idOverlay) {
                        idOverlay = null;
                    }
                    e.preventDefault();
                    th_overlay.close(idOverlay);
                });
        });
    },

    openOnLoad: function () {

        var current_hash = (window.location.hash).split('#');
        var founded_overlay = false;
        if (current_hash) {
            current_hash.forEach(function (element) {
                if (typeof th_overlay.hashList[element] !== 'undefined' && !founded_overlay) {
                    th_overlay.open(th_overlay.hashList[element]);
                    founded_overlay = true;
                }
            })
        }
    },

    open: function (overlayId, openShadow, doCallback) {
        if (openShadow !== false) {
            openShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        // RGAA : On liste les overlay ouvert pour les fermer sur echap
        th_overlay.lastOpenedId.push(overlayId);
        // RGAA : Focus de la croix de fermeture a l'ouverture de l'overlay
        setTimeout(function(){
            $('#' + overlayId).find('button[data-overlay-close]').first().focus();
        },250);



        $(th_overlay.selector_overlay + ".open").each(function (e) {
            th_overlay.close($(this).attr('id'), false, true)
        });

        $('#' + overlayId).addClass('open');

        if ($('#' + overlayId + '').data('hash') !== undefined) {
            window.location.hash = $('#' + overlayId + '').data('hash');
        }

        if (openShadow == true) {
            $(th_overlay.selector_overlay_shadow).addClass('open');
        }

        if (doCallback == true) {
            $.each(th_overlay.callbackOpen, function (k, callback) {
                callback(overlayId);
            });
        }
    },

    close: function (overlayId, closeShadow, doCallback) {

        if(!overlayId && th_overlay.lastOpenedId.length > 0){
            return th_overlay.close(th_overlay.lastOpenedId[th_overlay.lastOpenedId.length -1],closeShadow,doCallback);
        }

        if (closeShadow !== false) {
            closeShadow = true;
        }
        if (doCallback !== false) {
            doCallback = true;
        }

        window.location.hash = "";
        history.replaceState(null, null, ' ');


        if (overlayId) {

            if(th_overlay.lastOpenedId[th_overlay.lastOpenedId.length -1] == overlayId){
                th_overlay.lastOpenedId.pop();
            }

            $('#' + overlayId).removeClass('open');

            // RGAA : focus a nouveau sur l'element qui a ouvert l'overlay
            if(th_overlay.focusElOnClose[overlayId]){
                setTimeout(function(){
                    $(th_overlay.focusElOnClose[overlayId]).focus();
                },250);
            }


            if ($(th_overlay.selector_overlay + '.open').length == 0 && closeShadow) {
                $(th_overlay.selector_overlay_shadow).removeClass('open');
            }
        } else {
            $(th_overlay.selector_overlay + '.open').removeClass('open');
            if (closeShadow) {
                $(th_overlay.selector_overlay_shadow).removeClass('open');
            }
        }

        if (doCallback) {
            $.each(th_overlay.callbackClose, function (k, callback) {
                callback(overlayId);
            });
        }

    }

};